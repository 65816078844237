import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { useSelector } from 'react-redux';
import { RootState } from '../../constants/initialStore';

interface CutZoneOverlayProps {
  $radius?: number[];
}

const CutZoneOverlay: React.FC<CutZoneOverlayProps> = ({
  $radius = [0, 0, 0, 0],
}) => {
  const gridOverlayRef = useRef<HTMLDivElement>(null);

  const ratio = useSelector((state: RootState) => state.ratio.value);

  return (
    <CutZoneOverlayContainer
      ref={gridOverlayRef}
      $ratio={ratio}
      $radius={$radius}
    />
  );
};

const CutZoneOverlayContainer = styled.div<{
  $ratio: number;
  $radius: number[];
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  border: ${(props) => ConvertMMToPixel(1.5) * props.$ratio}px solid
    ${colors.error700};

  ${(props) =>
    props.$radius ? 'border-radius: ' + props.$radius.join('% ') + '%;' : ''}

  z-index: 999;
  background-color: transparent;

  overflow: hidden;
`;

export default CutZoneOverlay;
