import { styled } from 'styled-components';
import SavingPopup from '../../components/functionnalities/SavingPopup';
import {
  HorizontalTab,
  HorizontalTabContainer,
} from '../../components/general/HorizontalTab';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, changeRatio } from '../../constants/initialStore';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import TransformZoom from '../../components/general/TransformZoom';
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { useAutoSaveCalendar } from '../../hook/useAutoSaveCalendar';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import CalendarMonthStartModal from '../../components/functionnalities/modals/CalendarMonthStartModal';
import { useFetchOrCreateAlbum } from '../../hook/useFetchOrCreateAlbum';
import { useAutoSaveAlbum } from '../../hook/useAutoSaveAlbum';
import { AlbumType, albumFormats } from '../../constants/albumFormats';
import AlbumPage, { TransparentPageDisplayer } from './AlbumPage';
import AlbumPageDisplayer from './AlbumPage';
import CoverDisplayer from './CoverDisplayer';
import PageAdd from './PageAdd';
import colors from '../../constants/colors';
import { SpiraleContainer } from './AlbumPageStyles';
import useLittleScreen from '../../hook/useLittleScreen';
import { useTranslation } from 'react-i18next';

const INITIAL_PADDING_X = 170;
const INITIAL_PADDING_X_MOBILE = 14;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  background-color: ${colors.gray100};

  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const CalendarViewSwitcher = styled.div`
  display: flex;
  position: fixed;
  margin-top: 36px;
  top: 36px;
  z-index: 200;
`;

const AlbumPages = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(100px, fit-content));
  gap: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const MainAlbumZone: React.FC = () => {
  const dispatch = useDispatch();
  const album = useSelector((state: RootState) => state.creation.present.album);
  const isMenuOpen = useSelector((state: RootState) => !!state.menu.value);
  const [view, setView] = useState<'page' | 'global'>('global');
  const elRef = useRef<HTMLDivElement | null>(null);
  const format = album?.format;
  const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);

  const covers = [album?.albumCover, album?.albumBackCover];
  const pages = album?.albumPages;

  const littleScreen = useLittleScreen();

  const ratio = useSelector((state: RootState) => state.ratio.value);

  const { t } = useTranslation(['common']);

  useAutoSaveAlbum();

  useEffect(() => {
    if (!format) return;
    const { width, height } = albumFormats[format];

    const { clientHeight: zoneHeight, clientWidth: zoneWidth } = elRef.current!;

    let ratio = 0;
    if (view === 'page') {
      ratio = Math.min(
        (zoneWidth -
          2 * (littleScreen ? INITIAL_PADDING_X_MOBILE : INITIAL_PADDING_X)) /
          ConvertMMToPixel(width),
        (zoneHeight - 260) / ConvertMMToPixel(height),
      );
    } else if (view === 'global') {
      ratio = Math.min(
        (zoneWidth -
          2 * (littleScreen ? INITIAL_PADDING_X_MOBILE : INITIAL_PADDING_X) -
          24) /
          2 /
          ConvertMMToPixel(width),
        (zoneHeight - 260) / ConvertMMToPixel(height),
      );
    }
    ratio = Math.max(ratio, 0.01);
    dispatch(changeRatio(ratio));
  }, [view, format, isMenuOpen]);

  return (
    <Container ref={elRef} id="dropZone">
      <SavingPopup />
      <CalendarViewSwitcher
        style={{ left: littleScreen ? '50%' : 'calc(50% + 49px)' }}
      >
        <HorizontalTabContainer>
          <HorizontalTab
            $active={view === 'page'}
            onClickCapture={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setView('page');
            }}
          >
            {t('album.pageView')}
          </HorizontalTab>
          <HorizontalTab
            $active={view === 'global'}
            onClickCapture={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setView('global');
            }}
          >
            {t('album.globalView')}
          </HorizontalTab>
        </HorizontalTabContainer>
      </CalendarViewSwitcher>
      <TransformZoom
        ref={zoomRef}
        contentStyle={{
          width: '100%',
          alignItems: 'center',
        }}
        view={view}
      >
        {covers && covers[0] !== undefined && covers[1] !== undefined && (
          <CoverDisplayer
            cover={covers[0]}
            backCover={covers[1]}
            $viewPerPage={view === 'page'}
          />
        )}
        {pages && pages.length > 0 && (
          <AlbumPages>
            {[...pages]
              .sort((pageA, pageB) => pageA.position - pageB.position)
              .map((page, index) => {
                if (index % 2 === 1 || index === 0) {
                  return (
                    <>
                      <div
                        id={
                          'creationElement-' +
                          Math.ceil((index === 0 ? index : index + 1) / 2)
                        }
                        style={{
                          display: 'flex',
                          flexDirection: view === 'page' ? 'column' : 'row',
                          gap: '4px',
                          position: 'relative',
                        }}
                      >
                        {index === 0 && view === 'global' && (
                          <TransparentPageDisplayer key={index - 1} />
                        )}
                        {index === 0 &&
                          view === 'global' &&
                          albumFormats[album.format].type ===
                            AlbumType.SPIRALE && (
                            <SpiraleContainer
                              $height={
                                ConvertMMToPixel(
                                  albumFormats[album.format].height,
                                ) * ratio
                              }
                              $width={ConvertMMToPixel(20) * ratio}
                              $ratio={ratio}
                              src={'/spirale.png'}
                            />
                          )}
                        <AlbumPageDisplayer key={page.id} {...page} />
                        {index !== 0 &&
                          view === 'global' &&
                          albumFormats[album.format].type ===
                            AlbumType.SPIRALE && (
                            <SpiraleContainer
                              $height={
                                ConvertMMToPixel(
                                  albumFormats[album.format].height,
                                ) * ratio
                              }
                              $width={ConvertMMToPixel(20) * ratio}
                              $ratio={ratio}
                              src={'/spirale.png'}
                            />
                          )}
                        {index !== 0 && index !== pages.length - 1 && (
                          <AlbumPageDisplayer
                            key={pages[index + 1].id}
                            {...pages[index + 1]}
                          />
                        )}
                        {index === pages.length - 1 && view === 'global' && (
                          <TransparentPageDisplayer key={pages.length} />
                        )}
                      </div>
                      {pages.length < albumFormats[album.format].maxPages && (
                        <PageAdd index={index + 1} />
                      )}
                    </>
                  );
                }
              })}
          </AlbumPages>
        )}
        {covers &&
          covers[0] !== undefined &&
          covers[1] !== undefined &&
          view === 'page' && (
            <CoverDisplayer
              cover={covers[0]}
              backCover={covers[1]}
              $viewPerPage={view === 'page'}
              $isAtTheEnd={true}
            />
          )}
      </TransformZoom>
    </Container>
  );
};

export default MainAlbumZone;
