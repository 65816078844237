export enum ElementType {
  TEXT = 'TEXT',
  PHOTO = 'PHOTO',
  MOTIF = 'MOTIF',
  BLOCK = 'BLOCK',
}

export interface BlockElementStyle {
  gap: number;
  direction: 'row' | 'column';
  rotation: number;
}

export interface TextElementStyle {
  bold: boolean;
  italic: boolean;
  underlined: boolean;
  size: number;
  letterSpacing: number;
  interline: number;
  alignement: string;
  transform: string;
  font: string;
  color: string;
  rotation: number;
}

export interface PhotoElementStyle {
  brightness: number;
  saturation: number;
  opacity: number;
  zoom: number;
  rotation: number;
  rotationFrame: number;
  grayscale: boolean;
  sepia: boolean;
  mirrored: boolean;
  shadow: boolean;
  shape: 'rectangle' | 'circle' | 'arch' | 'diamond' | 'star';
  translateX: number;
  translateY: number;
}

export interface MotifElementStyle {
  rotation: number;
}

export interface Element {
  id?: string;
  calendarMonthID?: string;
  pageID?: string;
  type: ElementType;
  style:
    | TextElementStyle
    | PhotoElementStyle
    | MotifElementStyle
    | BlockElementStyle;
  width: number;
  height: number;
  top: number;
  left: number;
  content: string;
  face?: number;
  locked?: boolean;
  zIndex: number;
  toDelete?: boolean;
  editable: boolean;
  contentEditable: boolean;
  hide?: boolean;
  mustCover?: boolean;
  isForSlice?: boolean;
  children?: Element[];
}

export type LayoutElement = {
  type: ElementType;
  width: number;
  height: number;
  top: number;
  left: number;
  style?: Partial<Element['style']>;
  children?: LayoutElement[];
};
