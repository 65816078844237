import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  addElement,
  closeMenu,
  deleteElement,
  modifyElement,
  resetFocus,
  gridToggle,
  setFocus,
  openFolder,
  closeFolder,
} from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import RemoveElementModal from './modals/RemoveElementModal';
import IconButton from '../general/IconButton';
import Button, { buttonType } from '../general/Button';
import Checkbox from '../general/Checkbox';
import { PhotoFolder, PhotoType } from '../../types/models/Photos';
import Folder from './Folder';
import Photo from './Photo';
import RenameFolderModal from './modals/RenameFolderModal';
import DeleteFolderModal from './modals/DeleteFolderModal';
import DeletePhotoModal from './modals/DeletePhotoModal';
import ImportPhotoModal from './modals/ImportPhotoModal';
import {
  createFolder,
  deleteFolder,
  deletePhoto,
  getFolders,
  getPhotos,
  renameFolder,
} from '../../api/photos';
import CreateFolderModal from './modals/CreateFolderModal';
import RangeSlider from './Slider';
import ReplacePhotoModal from './modals/ReplacePhotoModal';
import { ElementType, PhotoElementStyle } from '../../types/models/Elements';
import { useTranslation } from 'react-i18next';
import { CloseButton, TitleContainer, TitleItem } from './MenuStyles';
import useTheme from '../../hook/useTheme';
import { LoginState, useAuth } from '../../hook/useAuth';
import NumberChooser from '../general/NumberChooser';
import ButtonText from '../general/ButtonText';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import ManagePixel from '../general/ManagePixel';
import ManageElementSize from '../general/ManageElementSize';
import Switch from '../general/Switch';
import CloseMenuButton from '../layout/CloseMenuButton';
import PhotoImporter from './PhotoImporter';
import { v4 as uuidv4 } from 'uuid';
import useCreationType from '../../hook/useCreationType';
import {
  getFreeSpaceHeightAlbum,
  getFreeSpaceHeightCalendar,
  getFreeSpaceWidthAlbum,
  getFreeSpaceWidthCalendar,
} from '../../utils/getFreeSpace';
import { CalendarTypes } from '../../types/models/Calendar';
import { calendarFormats } from '../../constants/calendarFormats';
import { albumFormats } from '../../constants/albumFormats';
import { otherFormats } from '../../constants/otherFormats';
import { ButtonLine } from '../general/ButtonLine';

export interface Dimensions {
  height: number;
  width: number;
}

interface PhotosDimensions {
  [index: string]: Dimensions;
}

const PhotoMenu: React.FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [displayUsedPhotos, setDisplayUsedPhotos] = useState<boolean>(false);
  const [renameFolderModalVisible, setRenameFolderModalVisible] =
    useState<boolean>(false);
  const [createFolderModalVisible, setCreateFolderModalVisible] =
    useState<boolean>(false);
  const [deleteFolderModalVisible, setDeleteFolderModalVisible] =
    useState<boolean>(false);
  const [deletePhotoModalVisible, setDeletePhotoModalVisible] =
    useState<boolean>(false);
  const [replacePhotoModalVisible, setReplacePhotoModalVisible] =
    useState<boolean>(false);
  const [importPhotoModal, setImportPhotoModal] = useState<boolean>(false);
  const [openedFolder, setOpenedFolder] = useState<boolean>(false);
  const [focusedFolder, setFocusedFolder] = useState<string | null>(null);
  const [openedPhoto, setOpenedPhoto] = useState<boolean>(false);
  const [focusedPhoto, setFocusedPhoto] = useState<string | null>(null);
  const [pixelHorizontalPerfect, setPixelHorizontalPerfect] =
    useState<number>(0);
  const [pixelVerticalPerfect, setPixelVerticalPerfect] = useState<number>(0);

  const [luminosityValue, setLuminosityValue] = useState<number>(1);
  const [contrastValue, setContrastValue] = useState<number>(100);
  const [opacityValue, setOpacityValue] = useState<number>(1);
  const [zoomValue, setZoomValue] = useState<number>(100);
  const [rotationValue, setRotationValue] = useState<number>(0);
  const [rotationFrameValue, setRotationFrameValue] = useState<number>(0);
  const [NBFilterActive, setNBFilterActive] = useState<boolean>(false);
  const [SepiaFilterActive, setSepiaFilterActive] = useState<boolean>(false);
  const [mirrorActive, setMirrorActive] = useState<boolean>(false);
  const [shadowActive, setShadowActive] = useState<boolean>(false);
  const [pictureShape, setPictureShape] = useState<
    'circle' | 'arch' | 'rectangle' | 'diamond' | 'star' | undefined
  >('rectangle');
  const [planValue, setPlanValue] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [mustCoverActive, setMustCoverActive] = useState<boolean>(false);

  const [folders, setFolders] = useState<PhotoFolder[]>([]);
  const [photos, setPhotos] = useState<PhotoType[]>([]);

  const [photosFiles, setPhotosFiles] = useState<{ [key: string]: File }>({});

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const autre = useSelector((state: RootState) => state.creation.present.other);

  const type = useCreationType();

  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const ratio = useSelector((state: RootState) => state.ratio.value);

  const currentFace = useSelector((state: RootState) => state.face.value);
  const menu = useSelector((state: RootState) => state.menu.value);
  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );

  const folderOpened = useSelector(
    (state: RootState) => state.folderOpened.value,
  );

  useEffect(() => {
    if (type === 'carte' && card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
    } else if (type === 'calendrier' && calendar) {
      setMaxWidth(
        getFreeSpaceWidthCalendar(
          calendar.type,
          calendarFormats[calendar.format],
        ),
      );
      setMaxHeight(
        getFreeSpaceHeightCalendar(
          calendar.type,
          calendarFormats[calendar.format],
        ),
      );
    } else if (type === 'album' && album) {
      setMaxWidth(
        getFreeSpaceWidthAlbum(
          albumFormats[album.format].type,
          albumFormats[album.format],
          false,
        ),
      );
      setMaxHeight(getFreeSpaceHeightAlbum(albumFormats[album.format]));
    } else if (type === 'autre' && autre) {
      setMaxWidth(
        parseInt(ConvertMMToPixel(otherFormats[autre.format].width).toFixed(0)),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(otherFormats[autre.format].height).toFixed(0),
        ),
      );
    }
  }, [type, card, calendar, album, autre]);

  const { t } = useTranslation(['common']);

  const focus = useSelector((state: RootState) => state.focus.value);
  const elementFocused = useSelector((state: RootState) =>
    state.creation.present.elements.value.find(
      (e) => e.id === focus && e.type === ElementType.PHOTO,
    ),
  );

  const dispatch = useDispatch();

  const { color } = useTheme();

  const CreateFolder = async (name: string) => {
    setCreateFolderModalVisible(false);
    const res = await createFolder(name);
    getFoldersFromServer().then(() => {
      OpenFolder(res.data.id);
    });
  };

  const RenameFolderClick = (id: string) => {
    setFocusedFolder(id);
    dispatch(openFolder(id));
    setRenameFolderModalVisible(true);
  };

  const RenameFolder = async (id: string, newName: string) => {
    await renameFolder(newName, id);
    setRenameFolderModalVisible(false);
    setFocusedFolder(null);
    dispatch(closeFolder());
    getFoldersFromServer();
  };

  const DeleteFolderClick = (id: string) => {
    setFocusedFolder(id);
    dispatch(openFolder(id));
    setDeleteFolderModalVisible(true);
  };

  const DeleteFolder = async (id: string) => {
    await deleteFolder(id);
    setDeleteFolderModalVisible(false);
    setFocusedFolder(null);
    dispatch(closeFolder());
    getFoldersFromServer();
  };

  const OpenFolder = (id: string) => {
    setPhotosFiles({});
    setFocusedFolder(id);
    dispatch(openFolder(id));
    setOpenedFolder(true);
  };

  const handleFolderBackClick = () => {
    setPhotosFiles({});
    setFocusedFolder(null);
    dispatch(closeFolder());
    setOpenedFolder(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePhotoBackClick = () => {
    setFocusedPhoto(null);
    setOpenedPhoto(false);
  };

  const DeletePhotoClick = (id: string) => {
    setFocusedPhoto(id);
    setDeletePhotoModalVisible(true);
  };

  const DeletePhoto = async (id: string) => {
    await deletePhoto(id);
    setDeletePhotoModalVisible(false);
    setFocusedPhoto(null);
    getPhotosFromServer();
  };

  const getFoldersFromServer = async () => {
    const res = await getFolders();
    setFolders(res.data);
  };

  const getPhotosFromServer = async () => {
    const res = await getPhotos();
    setPhotos(res.data);
  };

  const replacePhoto = async (
    index: number,
    photosDimension: { width: number; height: number },
    currentFocus: string | null,
  ) => {
    if (currentFocus) {
      const thisElement = elements.find(
        (element) => element.id === currentFocus,
      );
      if (thisElement && thisElement?.type === 'PHOTO') {
        dispatch(
          modifyElement({
            id: currentFocus,
            modification: {
              content: photos[index].pictureURL,
              style: {
                ...thisElement.style,
                brightness: 1,
                saturation: 100,
                opacity: 1,
                rotation: 0,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                shape: 'rectangle',
                translateX: 0,
                translateY: 0,
                zoom:
                  photosDimension.height / photosDimension.width >
                  thisElement.height / thisElement.width
                    ? (((photosDimension.height / photosDimension.width) *
                        thisElement.width) /
                        thisElement.height) *
                      100
                    : (((photosDimension.width / photosDimension.height) *
                        thisElement.height) /
                        thisElement.width) *
                      100,
              },
            },
          }),
        );
        dispatch(resetFocus());
        // dispatch(
        //   modifyElement({
        //     id: focus,
        //     modification: {
        //       content: photos[index].pictureURL,
        //       style: {
        //         ...thisElement.style,
        //         zoom:
        //           photosDimensions[index].height > photosDimensions[index].width
        //             ? (((photosDimensions[index].height /
        //                 photosDimensions[index].width) *
        //                 thisElement.width) /
        //                 thisElement.height) *
        //               100
        //             : (((photosDimensions[index].width /
        //                 photosDimensions[index].height) *
        //                 thisElement.height) /
        //                 thisElement.width) *
        //               100,
        //       },
        //     },
        //   }),
        // );
      }
    }
  };

  const DuplicatePhoto = () => {
    if (elementFocused) {
      const elementToDuplicate = {
        ...elementFocused,
        top: elementFocused.top + 20,
        left: elementFocused.left + 20,
      };
      delete elementToDuplicate.id;
      dispatch(addElement({ element: elementToDuplicate }));
    }
  };

  const downloadImage = () => {
    if (elementFocused) {
      const url = elementFocused.content;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const resetPhoto = () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            style: {
              brightness: 1,
              saturation: 100,
              opacity: 1,
              zoom: 100,
              rotation: 0,
              rotationFrame: 0,
              grayscale: false,
              sepia: false,
              mirrored: false,
              shadow: false,
              shape: 'rectangle',
              translateX: 0,
              translateY: 0,
            },
          },
        }),
      );
    }
  };

  const DeletePhotoFromCreation = async () => {
    if (
      focus &&
      elements.find((element) => element.id === focus)?.type === 'PHOTO' &&
      elements.find((element) => element.id === focus)?.editable === false &&
      !isAdmin
    ) {
      setDeletePhotoModalVisible(false);
      dispatch(
        modifyElement({
          id: focus,
          modification: {
            content: '',
            locked: false,
            style: {
              style: {
                brightness: 1,
                saturation: 100,
                opacity: 1,
                zoom: 100,
                rotation: 0,
                rotationFrame: 0,
                grayscale: false,
                sepia: false,
                mirrored: false,
                shadow: false,
                shape: 'rectangle',
                translateX: 0,
                translateY: 0,
              },
            },
          },
        }),
      );
    } else {
      const focusedID = focus;
      dispatch(resetFocus());
      setOpenedPhoto(false);
      setFocusedPhoto(null);
      dispatch(deleteElement({ id: focusedID }));
      setDeletePhotoModalVisible(false);
    }
  };

  const lockUnlockPhoto = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            editable: !elementFocused.editable,
          },
        }),
      );
    }
  };
  const lockUnlockElementContent = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            contentEditable: !elementFocused.contentEditable,
          },
        }),
      );
    }
  };

  const handleHiddenElement = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            hide: !elementFocused.hide,
          },
        }),
      );
    }
  };

  const loadEnd = async (id: string, newPhoto: PhotoType) => {
    setPhotos((curr_photos) => [...curr_photos, newPhoto]);
    setPhotosFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[id];
      return updatedFiles;
    });
  };

  const addPhotosToImporter = async (photosToAdd: File[]) => {
    const newFilesObject: { [key: string]: File } = {};

    Array.from(photosToAdd).forEach((file) => {
      const uniqueId = uuidv4();
      newFilesObject[uniqueId] = file;
    });

    setPhotosFiles((prevFiles) => ({
      ...prevFiles,
      ...newFilesObject,
    }));
  };

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (elementFocused && isReady) {
      const shape = pictureShape;
      let localWidth = width;
      let localHeight = height;
      if (
        shape !== (elementFocused.style as PhotoElementStyle).shape &&
        (shape === 'circle' || shape === 'arch')
      ) {
        const length = Math.min(width, height);
        localWidth = length;
        localHeight = length;
      }
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            width: localWidth,
            height: localHeight,
            top: pixelVerticalPerfect,
            left: pixelHorizontalPerfect,
            zIndex: planValue,
            mustCover: mustCoverActive,
            style: {
              ...elementFocused.style,
              brightness: luminosityValue,
              saturation: contrastValue,
              opacity: opacityValue,
              zoom: zoomValue,
              rotation: rotationValue,
              rotationFrame: rotationFrameValue,
              grayscale: NBFilterActive,
              sepia: SepiaFilterActive,
              mirrored: mirrorActive,
              shadow: shadowActive,
              shape: pictureShape,
            },
          },
        }),
      );
    }
  }, [
    luminosityValue,
    contrastValue,
    opacityValue,
    zoomValue,
    rotationValue,
    rotationFrameValue,
    NBFilterActive,
    SepiaFilterActive,
    mirrorActive,
    shadowActive,
    pictureShape,
    pixelHorizontalPerfect,
    pixelVerticalPerfect,
    width,
    height,
    planValue,
    mustCoverActive,
  ]);

  useEffect(() => {
    if (
      elementFocused &&
      isReady &&
      'zoom' in elementFocused.style &&
      elementFocused.style.zoom !== zoomValue
    ) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            style: {
              ...elementFocused.style,
              zoom: zoomValue,
            },
          },
        }),
      );
    }
  }, [zoomValue]);

  useEffect(() => {
    if (menu !== 1 && focusedPhoto) {
      dispatch(resetFocus());
      setOpenedPhoto(false);
      setFocusedPhoto(null);
    }
  }, [focusedPhoto, menu]);

  useEffect(() => {
    if (elementFocused) {
      const photo = elementFocused;
      setOpenedPhoto(true);
      setFocusedPhoto(photo.id ?? null);
      setPixelHorizontalPerfect(photo.left ? parseInt(String(photo.left)) : 0);
      setPixelVerticalPerfect(photo.top ? parseInt(String(photo.top)) : 0);
      setWidth(parseInt(String(photo.width)));
      setHeight(parseInt(String(photo.height)));
      setMustCoverActive(photo.mustCover ?? false);
      if ('opacity' in photo.style) {
        setPlanValue(photo.zIndex);
        setContrastValue(photo.style.saturation);
        setLuminosityValue(photo.style.brightness);
        setOpacityValue(photo.style.opacity);
        setZoomValue(photo.style.zoom);
        setRotationValue(photo.style.rotation);
        setRotationFrameValue(
          parseInt(
            photo.style.rotationFrame
              ? photo.style.rotationFrame.toString()
              : '0',
          ),
        );
        setNBFilterActive(photo.style.grayscale);
        setSepiaFilterActive(photo.style.sepia);
        setMirrorActive(photo.style.mirrored);
        setShadowActive(photo.style.shadow);

        setPictureShape(photo.style.shape ?? 'rectangle');
        setIsReady(true);
      }
    }
  }, [elementFocused, focus, elementFocused?.content]);

  useEffect(() => {
    getFoldersFromServer();
    getPhotosFromServer();
  }, []);

  useEffect(() => {
    if (folderOpened === '') {
      setFocusedFolder(null);
      setOpenedFolder(false);
    } else {
      setFocusedFolder(folderOpened);
      setOpenedFolder(true);
    }
  }, [folderOpened]);

  if (
    elementFocused &&
    !elementFocused.editable &&
    !isAdmin &&
    focusedPhoto &&
    elementFocused.content !== ''
  ) {
    return (
      <Container>
        <RemoveElementModal
          visible={deletePhotoModalVisible}
          setVisible={setDeletePhotoModalVisible}
          handleValidate={DeletePhotoFromCreation}
          id={focus ?? undefined}
        />
        <ImportPhotoModal
          visible={importPhotoModal}
          setVisible={setImportPhotoModal}
          folderID={undefined}
          addPhotosToImport={addPhotosToImporter}
        />
        <ReplacePhotoModal
          visible={replacePhotoModalVisible}
          setVisible={setReplacePhotoModalVisible}
          handleValidate={(inIndex: number, photosDimension: Dimensions) =>
            replacePhoto(inIndex, photosDimension, focus)
          }
          photos={photos}
          folders={folders}
          photosFiles={photosFiles}
          openImport={setImportPhotoModal}
          loadEnd={loadEnd}
        />
        <CloseMenuButton />
        <Item>
          <SliderLabel>{t('photos.zoomInImage')}</SliderLabel>
          <RangeSlider
            value={zoomValue}
            onChange={(newValue) => {
              setZoomValue(newValue);
            }}
            max={500}
            min={50}
            unit={'%'}
          />
        </Item>
        <Item>
          <Button
            $type={buttonType.black}
            onClick={() => {
              setReplacePhotoModalVisible(true);
            }}
          >
            {t('photos.replacePhoto')}
          </Button>
        </Item>
        <Item>
          <IconButtonLine>
            <IconButton
              title={t('photos.mirror')}
              src={
                mirrorActive ? '/svg/mirror_white.svg' : '/svg/mirror_black.svg'
              }
              disabled={focus === null}
              active={mirrorActive}
              onClick={() => {
                setMirrorActive(!mirrorActive);
              }}
            />
            <IconButton
              title={t('photos.shadow')}
              src={
                shadowActive ? '/svg/shadow_white.svg' : '/svg/shadow_black.svg'
              }
              disabled={focus === null}
              active={shadowActive}
              onClick={() => {
                setShadowActive(!shadowActive);
              }}
            />
            {/* <IconButton
              title={t('photos.duplicate')}
              src="/svg/duplicate.svg"
              disabled={focus === null}
              onClick={DuplicatePhoto}
            /> */}
            <IconButton
              title={t('photos.deletePhoto')}
              src="/svg/trash.svg"
              disabled={focus === null}
              onClick={() => {
                DeletePhotoClick(focusedPhoto);
              }}
              isDeletion={true}
              style={{ color: colors.red }}
            />
          </IconButtonLine>
        </Item>
        <Item>
          <SliderLabel>{t('photos.filters')}</SliderLabel>
          <FilterLine>
            <Filter
              onClick={() => {
                setNBFilterActive(false);
                setSepiaFilterActive(false);
              }}
            >
              <FilterPicture
                $active={!NBFilterActive && !SepiaFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.original')}
            </Filter>
            <Filter
              onClick={() => {
                setNBFilterActive(true);
                setSepiaFilterActive(false);
              }}
            >
              <FilterPictureNB
                $active={NBFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.blackAndWhite')}
            </Filter>
            <Filter
              onClick={() => {
                setNBFilterActive(false);
                setSepiaFilterActive(true);
              }}
            >
              <FilterPictureSepia
                $active={SepiaFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.sepia')}
            </Filter>
          </FilterLine>
        </Item>
        <Item>
          <SliderLabel>{t('photos.rotateImage')}</SliderLabel>
          <RangeSlider
            value={rotationValue}
            onChange={(newValue) => {
              setRotationValue(newValue);
            }}
            max={180}
            min={-180}
            unit={'°'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.rotateFrame')}</SliderLabel>
          <RangeSlider
            value={rotationFrameValue}
            onChange={(newValue) => {
              setRotationFrameValue(newValue);
            }}
            max={180}
            min={-180}
            unit={'°'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.brightness')}</SliderLabel>
          <RangeSlider
            value={luminosityValue}
            onChange={(newValue) => {
              setLuminosityValue(newValue);
            }}
            max={2}
            min={0.01}
            step={0.01}
            unit={'%'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.contrast')}</SliderLabel>
          <RangeSlider
            value={contrastValue}
            onChange={(newValue) => {
              setContrastValue(newValue);
            }}
            max={200}
            min={1}
            step={1}
            unit={'%'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.opacity')}</SliderLabel>
          <RangeSlider
            value={opacityValue}
            onChange={(newValue) => {
              setOpacityValue(newValue);
            }}
            max={1}
            min={0}
            step={0.01}
            unit={'%'}
          />
        </Item>
      </Container>
    );
  }

  if (
    openedPhoto &&
    focusedPhoto &&
    elementFocused &&
    elementFocused.content !== ''
  ) {
    return (
      <Container>
        <RemoveElementModal
          visible={deletePhotoModalVisible}
          setVisible={setDeletePhotoModalVisible}
          handleValidate={DeletePhotoFromCreation}
        />
        <ImportPhotoModal
          visible={importPhotoModal}
          setVisible={setImportPhotoModal}
          folderID={undefined}
          addPhotosToImport={addPhotosToImporter}
        />
        <ReplacePhotoModal
          visible={replacePhotoModalVisible}
          setVisible={setReplacePhotoModalVisible}
          handleValidate={(inIndex: number, photosDimension: Dimensions) =>
            replacePhoto(inIndex, photosDimension, focus)
          }
          photos={photos}
          folders={folders}
          photosFiles={photosFiles}
          openImport={setImportPhotoModal}
          loadEnd={loadEnd}
        />
        <CloseMenuButton />
        <Item>
          <SliderLabel>{t('photos.zoomInImage')}</SliderLabel>
          <RangeSlider
            value={zoomValue}
            onChange={(newValue) => {
              setZoomValue(newValue);
            }}
            max={500}
            min={50}
            unit={'%'}
          />
        </Item>
        <ItemRow>
          <SliderLabel>{t('photos.ajusting')}</SliderLabel>
          <Switch
            isChecked={elementFocused.locked ?? false}
            setIsChecked={() =>
              dispatch(
                modifyElement({
                  id: elementFocused.id,
                  modification: {
                    locked: !elementFocused.locked,
                  },
                }),
              )
            }
            label={''}
          />
        </ItemRow>
        <Item style={{ marginTop: '10px' }}>
          <Button
            $type={buttonType.black}
            onClick={() => {
              setReplacePhotoModalVisible(true);
            }}
          >
            {t('photos.replacePhoto')}
          </Button>
        </Item>
        <Item>
          <SliderLabel>{t('photos.imageShape')}</SliderLabel>
          <FilterLine>
            <Filter
              onClick={() => {
                setPictureShape('circle');
              }}
            >
              <FilterIcon $active={pictureShape === 'circle'}>
                <ReactSVG
                  src="/svg/circle.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'width: 24px; height: 24px;');
                  }}
                />
              </FilterIcon>
              {t('photos.circle')}
            </Filter>
            <Filter
              onClick={() => {
                setPictureShape('arch');
              }}
            >
              <FilterIcon $active={pictureShape === 'arch'}>
                <ReactSVG
                  src="/svg/arch.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'width: 24px; height: 24px;');
                  }}
                />
              </FilterIcon>
              {t('photos.arch')}
            </Filter>
            <Filter
              onClick={() => {
                setPictureShape('rectangle');
              }}
            >
              <FilterIcon $active={pictureShape === 'rectangle'}>
                <ReactSVG
                  src="/svg/rectangle.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'width: 24px; height: 24px;');
                  }}
                />
              </FilterIcon>
              {t('photos.rectangle')}
            </Filter>
          </FilterLine>
        </Item>
        <Item>
          <IconButtonLine>
            <IconButton
              title={t('photos.mirror')}
              src={
                mirrorActive ? '/svg/mirror_white.svg' : '/svg/mirror_black.svg'
              }
              disabled={focus === null}
              active={mirrorActive}
              onClick={() => {
                setMirrorActive(!mirrorActive);
              }}
            />
            <IconButton
              title={t('photos.shadow')}
              src={
                shadowActive ? '/svg/shadow_white.svg' : '/svg/shadow_black.svg'
              }
              disabled={focus === null}
              active={shadowActive}
              onClick={() => {
                setShadowActive(!shadowActive);
              }}
            />
            {/* <IconButton
              title={t('photos.duplicate')}
              src="/svg/duplicate.svg"
              disabled={focus === null}
              onClick={DuplicatePhoto}
            /> */}
            <IconButton
              title={t('photos.deletePhoto')}
              src="/svg/trash.svg"
              disabled={focus === null}
              onClick={() => {
                DeletePhotoClick(focusedPhoto);
              }}
              isDeletion={true}
              style={{ color: colors.red }}
            />
          </IconButtonLine>
        </Item>
        <Item>
          <SliderLabel>{t('photos.filters')}</SliderLabel>
          <FilterLine>
            <Filter
              onClick={() => {
                setNBFilterActive(false);
                setSepiaFilterActive(false);
              }}
            >
              <FilterPicture
                $active={!NBFilterActive && !SepiaFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.original')}
            </Filter>
            <Filter
              onClick={() => {
                setNBFilterActive(true);
                setSepiaFilterActive(false);
              }}
            >
              <FilterPictureNB
                $active={NBFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.blackAndWhite')}
            </Filter>
            <Filter
              onClick={() => {
                setNBFilterActive(false);
                setSepiaFilterActive(true);
              }}
            >
              <FilterPictureSepia
                $active={SepiaFilterActive}
                src={'/pictures/picture_example.png'}
              />
              {t('photos.sepia')}
            </Filter>
          </FilterLine>
        </Item>
        <Item>
          <SliderLabel>{t('photos.rotateImage')}</SliderLabel>
          <RangeSlider
            value={rotationValue}
            onChange={(newValue) => {
              setRotationValue(newValue);
            }}
            max={180}
            min={-180}
            unit={'°'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.rotateFrame')}</SliderLabel>
          <RangeSlider
            value={rotationFrameValue}
            onChange={(newValue) => {
              setRotationFrameValue(newValue);
            }}
            max={180}
            min={-180}
            unit={'°'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.brightness')}</SliderLabel>
          <RangeSlider
            value={luminosityValue}
            onChange={(newValue) => {
              setLuminosityValue(newValue);
            }}
            max={2}
            min={0.01}
            step={0.01}
            unit={'%'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.contrast')}</SliderLabel>
          <RangeSlider
            value={contrastValue}
            onChange={(newValue) => {
              setContrastValue(newValue);
            }}
            max={200}
            min={1}
            step={1}
            unit={'%'}
          />
        </Item>
        <Item>
          <SliderLabel>{t('photos.opacity')}</SliderLabel>
          <RangeSlider
            value={opacityValue}
            onChange={(newValue) => {
              setOpacityValue(newValue);
            }}
            max={1}
            min={0}
            step={0.01}
            unit={'%'}
          />
        </Item>
        <Button
          $type={buttonType.white}
          onClick={() => setPixelHorizontalPerfect((maxWidth - width) / 2)}
        >
          {t('photos.centerH')}
        </Button>
        <Button
          $type={buttonType.white}
          onClick={() => setPixelVerticalPerfect((maxHeight - height) / 2)}
        >
          {t('photos.centerV')}
        </Button>

        {isAdmin && (
          <Item>
            <ManagePixel
              translateKey={t('photos.pixelHorizontal')}
              pixelValue={pixelHorizontalPerfect}
              setPixelValue={setPixelHorizontalPerfect}
              maxSize={maxWidth}
              sizeElement={width}
            />
            <ManagePixel
              translateKey={t('photos.pixelVertical')}
              pixelValue={pixelVerticalPerfect}
              setPixelValue={setPixelVerticalPerfect}
              maxSize={maxHeight}
              sizeElement={height}
            />
          </Item>
        )}
        {isAdmin && (
          <Item>
            <ManageElementSize
              translateKey={t('photos.sizeWidthElement')}
              value={width}
              setValue={(e) => setWidth(e as number)}
              maxSize={maxWidth}
            />
            <ManageElementSize
              translateKey={t('photos.sizeHeightElement')}
              value={height}
              setValue={(e) => setHeight(e as number)}
              maxSize={maxHeight}
            />
          </Item>
        )}
        {isAdmin && (
          <Item>
            <FilterLine>
              <NumberChooser
                title={t('photos.plan')}
                number={planValue}
                setNumber={setPlanValue}
                step={1}
              />
            </FilterLine>
          </Item>
        )}
        {isAdmin && (
          <ItemRow>
            <SliderLabel>{'Couvrir'}</SliderLabel>
            <Switch
              isChecked={!elementFocused.mustCover ?? false}
              setIsChecked={() =>
                dispatch(
                  modifyElement({
                    id: elementFocused.id,
                    modification: {
                      mustCover: !elementFocused.mustCover,
                    },
                  }),
                )
              }
              label={''}
            />
            <SliderLabel>{'Contenir'}</SliderLabel>
          </ItemRow>
          // <IconButtonLine>
          //   <IconButton
          //     title={'Couvrir'}
          //     src={''}
          //     disabled={false}
          //     active={!mustCoverActive}
          //     onClick={() => setMustCoverActive(!mustCoverActive)}
          //   />
          //   <IconButton
          //     title={'Contenir'}
          //     src={''}
          //     disabled={false}
          //     active={mustCoverActive}
          //     onClick={() => setMustCoverActive(!mustCoverActive)}
          //   />
          // </IconButtonLine>
        )}
        <Item>
          {isAdmin && elementFocused?.hide && (
            <SubTitle>L'élement a été caché pour les utilisateurs</SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={handleHiddenElement}>
              {elementFocused?.hide ? 'Montrer ' : 'Cacher '}
              la photo
            </Button>
          )}
        </Item>
        <Item>
          {isAdmin && !elementFocused?.editable && (
            <SubTitle>
              L'élement a été verrouillé pour les utilisateurs
            </SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={lockUnlockPhoto}>
              {elementFocused?.editable ? 'Verrouiller ' : 'Déverrouiller '}
              la photo
            </Button>
          )}
        </Item>
        <Item>
          {isAdmin && !elementFocused?.contentEditable && (
            <SubTitle>
              L'élement a été verrouillé pour les utilisateurs
            </SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={lockUnlockElementContent}>
              {elementFocused?.contentEditable
                ? 'Verrouiller '
                : 'Déverrouiller '}
              le contenu de la photo
            </Button>
          )}
        </Item>
        <Item>
          {isAdmin && (
            <Button $type={buttonType.white} onClick={downloadImage}>
              Télécharger la photo
            </Button>
          )}
        </Item>
        {/*<Item>
          <Button $type={buttonType.white} onClick={lockUnlockPhoto}>
            {elements.find((element) => element.id === focusedPhoto)?.locked
              ? 'Déverrouiller '
              : 'Verrouiller '}
            le cadre de la photo
          </Button>
        </Item>*/}
        <Item>
          <Button $type={buttonType.black} onClick={resetPhoto}>
            {t('photos.resetPhoto')}
          </Button>
        </Item>
      </Container>
    );
  }

  if (openedFolder && focusedFolder) {
    return (
      <Container>
        <ImportPhotoModal
          visible={importPhotoModal}
          setVisible={setImportPhotoModal}
          folderID={focusedFolder}
          addPhotosToImport={addPhotosToImporter}
        />
        {photos.length > 1 && (
          <DeletePhotoModal
            visible={deletePhotoModalVisible}
            setVisible={setDeletePhotoModalVisible}
            handleValidate={DeletePhoto}
            photo={photos.find((photo) => photo.id === focusedPhoto) ?? null}
          />
        )}
        <Item
          style={{
            cursor: 'pointer',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={handleFolderBackClick}
        >
          <ReactSVG
            src="/svg/left_chevron.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 10px; height: 10px;');
            }}
          />
          <SubTitle>Retour</SubTitle>
        </Item>
        <Item>
          <Title>
            {folders.find((folder) => folder.id === focusedFolder)?.name}
          </Title>
          <Line />
        </Item>
        <Item>
          <Button
            $type={buttonType.themed}
            onClick={() => setImportPhotoModal(true)}
            $themeColor={color}
          >
            {t('photos.import')}
          </Button>
        </Item>
        {/* <Item>{t('photos.dragPhotoToAdd')}</Item> */}
        {/* <Item>
          <Checkbox
            checked={displayUsedPhotos}
            setChecked={setDisplayUsedPhotos}
          >
            {t('photos.doNotShowUsedPhotos')}
          </Checkbox>
        </Item> */}
        <ListContainer>
          {photos
            .filter((photo) => photo.folderID === focusedFolder)
            .map((photo, index) => {
              return (
                <Photo
                  photoSrc={photo.pictureURL}
                  id={photo.id}
                  deleteClick={DeletePhotoClick}
                />
              );
            })}
          {Object.keys(photosFiles).map((key, index) => {
            const photo = photosFiles[key];
            if (photo) {
              return (
                <PhotoImporter
                  key={index}
                  file={photo}
                  id={key}
                  folderID={focusedFolder}
                  loadEnd={loadEnd}
                />
              );
            }
          })}
        </ListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ImportPhotoModal
        visible={importPhotoModal}
        setVisible={setImportPhotoModal}
        folderID={undefined}
        addPhotosToImport={addPhotosToImporter}
      />
      <CreateFolderModal
        visible={createFolderModalVisible}
        setVisible={setCreateFolderModalVisible}
        handleValidate={CreateFolder}
      />
      <RenameFolderModal
        visible={renameFolderModalVisible}
        setVisible={setRenameFolderModalVisible}
        handleValidate={RenameFolder}
        folder={folders.find((folder) => folder.id === focusedFolder) ?? null}
      />
      <DeleteFolderModal
        visible={deleteFolderModalVisible}
        setVisible={setDeleteFolderModalVisible}
        handleValidate={DeleteFolder}
        folder={folders.find((folder) => folder.id === focusedFolder) ?? null}
      />
      <DeletePhotoModal
        visible={deletePhotoModalVisible}
        setVisible={setDeletePhotoModalVisible}
        handleValidate={DeletePhoto}
        photo={photos.find((photo) => photo.id === focusedPhoto) ?? null}
      />
      <CloseMenuButton />
      <Item>
        <Button
          $type={buttonType.themed}
          onClick={() => setImportPhotoModal(true)}
          $themeColor={color}
        >
          {t('photos.import')}
        </Button>
      </Item>
      <Item>
        <SubTitle>{t('photos.myPhotos')}</SubTitle>
      </Item>
      {/* <Item>
        <Checkbox checked={displayUsedPhotos} setChecked={setDisplayUsedPhotos}>
          {t('photos.doNotShowUsedPhotos')}
        </Checkbox>
      </Item> */}
      <ListContainer>
        {photos
          .filter((photo) => photo.folderID === null)
          .map((photo, index) => {
            return (
              <Photo
                key={photo.id}
                photoSrc={photo.pictureURL}
                id={photo.id}
                deleteClick={DeletePhotoClick}
              />
            );
          })}
        {Object.keys(photosFiles).map((key, index) => {
          const photo = photosFiles[key];
          if (photo) {
            return (
              <PhotoImporter
                key={index}
                file={photo}
                id={key}
                folderID={''}
                loadEnd={loadEnd}
              />
            );
          }
        })}
      </ListContainer>
      <Item
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SubTitle>{t('photos.photoFolders')}</SubTitle>
        <AddButton
          onClick={() => setCreateFolderModalVisible(true)}
          $color={color}
        >
          <ReactSVG
            src="/svg/plus_white.svg"
            beforeInjection={(svg) => {
              svg.setAttribute(
                'style',
                'width: 12px; height: 12px; margin-top: 4px;',
              );
            }}
          />
        </AddButton>
      </Item>
      <ListContainer>
        {folders.map((folder) => {
          return (
            <Folder
              key={folder.id}
              folder={folder}
              totalPhotos={
                photos.filter((photo) => photo.folderID === folder.id).length
              }
              openFolder={OpenFolder}
              deleteFolder={DeleteFolderClick}
              renameFolder={RenameFolderClick}
              reloadPhotos={() => {
                getFoldersFromServer();
                getPhotosFromServer();
              }}
            />
          );
        })}
      </ListContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  color: ${colors.black};

  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;

  box-sizing: border-box;
  gap: 24px;

  padding-top: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;
`;

const ItemRow = styled(Item)`
  flex-direction: row;
  justify-content: space-between;
  padding-right: 18px;
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: ${colors.black};
`;

const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${colors.gray900};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  box-sizing: border-box;

  flex-wrap: wrap;

  & > div {
    margin-top: 8px;
  }
`;

const AddButton = styled.div<{ $color: string }>`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background-color: ${(props) => props.$color};

  cursor: pointer;
`;

const SliderLabel = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const FilterLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const RowTextButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;

  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const FilterPicture = styled.img<{ $active: boolean }>`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 20px;
  border: ${(props) => (props.$active ? 'solid 1px ' + colors.black : 'none')};
  box-sizing: border-box;
`;

const FilterIcon = styled.div<{ $active: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  border: ${(props) => (props.$active ? 'solid 1px ' + colors.black : 'none')};
  box-sizing: border-box;

  > div > div {
    display: flex;
    align-items: center;
  }
`;

const FilterPictureNB = styled(FilterPicture)`
  filter: grayscale(100%);
`;

const FilterPictureSepia = styled(FilterPicture)`
  filter: sepia(100%);
`;

const IconButtonLine = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 8px;

  justify-content: flex-start;

  gap: 16px;
`;

export default PhotoMenu;
