import { styled } from 'styled-components';
import { useSelector } from 'react-redux';

import { ElementType, MotifElementStyle } from '../../../types/models/Elements';
import { RootState, changePreviewRatio } from '../../../constants/initialStore';
import colors from '../../../constants/colors';
import ImmutableText from '../../cardElements/textElements/ImmutableText';
import { useEffect, useRef } from 'react';
import { ConvertMMToPixel } from '../../../utils/convertCMToPixel';
import ImmutablePhoto from '../../cardElements/photoElements/ImmutablePhoto';
import { ImmutableMotif } from '../../cardElements/motifElements/ImmutableMotif';
import FacesDisplayer from '../../cardElements/FacesDisplayer';
import useCardZoneSize from '../../../hook/useCardZoneSize';

const CardPreview: React.FC = () => {
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const ratio = useSelector((state: RootState) => state.previewRatio!.value);

  const cardElements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const currentFace = useSelector((state: RootState) => state.face.value);

  const maxZoneRef = useRef<HTMLDivElement>(null);

  const [, , displayHeight, displayWidth] = useCardZoneSize(
    changePreviewRatio,
    maxZoneRef,
  );

  useEffect(() => {
    console.log(cardElements);
  }, [cardElements]);

  return (
    <MainContainer>
      <Title>{card.name}</Title>
      <FacesDisplayer />
      <MaxZone ref={maxZoneRef}>
        <CardContainer
          $ratio={ratio}
          $bgcolor={card.facesSizes[currentFace].bgColor}
          $bgtheme={card.facesSizes[currentFace].bgTheme}
          $height={displayHeight}
          $width={displayWidth}
          $radius={card.facesSizes[currentFace].radius}
        >
          <Watermark src={'/watermark.png'} />
          {cardElements.map((element) => {
            if (currentFace === element.face) {
              switch (element.type) {
                case ElementType.TEXT:
                  return (
                    <ImmutableText
                      key={element.id}
                      element={element}
                      ratio={ratio}
                    />
                  );
                case ElementType.PHOTO:
                  return (
                    <ImmutablePhoto
                      key={element.id}
                      src={element.content}
                      element={element}
                      elementstyle={
                        element.style && 'opacity' in element.style
                          ? element.style
                          : null
                      }
                      ratio={ratio}
                    />
                  );
                case ElementType.MOTIF:
                  return (
                    <ImmutableMotif
                      src={element.content}
                      key={element.id}
                      $element={element}
                      $elementstyle={element.style as MotifElementStyle}
                      $ratio={ratio}
                      $index={element.zIndex}
                    />
                  );
              }
            }
          })}
          {card.facesSizes[currentFace].horizontalBend.map((hBend, index) => {
            return (
              <HBend key={index} $position={ConvertMMToPixel(hBend) * ratio} />
            );
          })}
          {card.facesSizes[currentFace].verticalBend.map((vBend, index) => {
            return (
              <VBend key={index} $position={ConvertMMToPixel(vBend) * ratio} />
            );
          })}
          {card.facesSizes[currentFace].forbiddenZones?.map((zone, index) => {
            return (
              <ForbiddenZone
                key={index}
                $top={ConvertMMToPixel(zone.top) * ratio}
                $left={ConvertMMToPixel(zone.left) * ratio}
                $width={ConvertMMToPixel(zone.width) * ratio}
                $height={ConvertMMToPixel(zone.height) * ratio}
                $radius={zone.radius.map(
                  (thisRadius) => ConvertMMToPixel(thisRadius) * ratio,
                )}
              />
            );
          })}
        </CardContainer>
      </MaxZone>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 24px;
  line-height: 24px;
  color: ${colors.black};
`;

const MaxZone = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div<{
  $ratio: number;
  $bgcolor?: string;
  $bgtheme?: string;
  $path?: string;
  $height: number;
  $width: number;
  $radius: number[];
}>`
  overflow: hidden;
  max-height: 100%;
  height: ${(props) => `${props.$height}px`};
  width: ${(props) => `${props.$width}px`};
  aspect-ratio: 1;
  background-color: ${(props) =>
    props.$bgcolor && props.$bgcolor !== 'null'
      ? props.$bgcolor
      : colors.gray300};
  background-image: ${(props) =>
    props.$bgtheme && props.$bgtheme !== 'null'
      ? 'url(' + props.$bgtheme + ')'
      : 'none'};
  background-size: cover;
  border-radius: ${(props) =>
    props.$radius.map(
      (radius) => ConvertMMToPixel(radius) * props.$ratio + 'px ',
    )};

  position: relative;
  transform: translate(0, 0);

  box-sizing: border-box;
`;

const Watermark = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1000;
`;

const HBend = styled.div<{ $position: number }>`
  position: absolute;
  top: ${(props) => props.$position - 0.5}px;
  height: 1px;
  width: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const VBend = styled.div<{ $position: number }>`
  position: absolute;
  left: ${(props) => props.$position - 0.5}px;
  top: 0px;
  width: 1px;
  height: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const ForbiddenZone = styled.div<{
  $top: number;
  $left: number;
  $width: number;
  $height: number;
  $radius: number[];
}>`
  position: absolute;

  background-color: ${colors.gray900};

  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  border-radius: ${(props) => props.$radius.join('px ')}px;

  z-index: 250;
`;

export default CardPreview;
