import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CalendarPage, MonthPage } from '../calendars/CalendarMonth';
import { useDispatch } from 'react-redux';
import { changeFace, changeRatio } from '../../constants/initialStore';
import { Calendar } from '../../types/models/Calendar';
import { fetchDispatchCalendar } from '../../hook/useFetchOrCreateCalendar';
import { fetchDispatchCard } from '../../hook/useFetchOrCreateCard';
import DrawZone from '../../components/cardElements/DrawZone';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useImportTextFonts from '../../hook/useImportTextFonts';
import styled from 'styled-components';
import OtherDrawZone from '../others/OtherDrawZone';
import { otherFormats } from '../../constants/otherFormats';
import { Page } from '../albums/AlbumPage';
import { Album } from '../../types/models/Album';
import { fetchDispatchOther } from '../../hook/useFecthOrCreateOther';
import { fetchAlbum } from '../../hook/useFetchOrCreateAlbum';
import { LoginState, useAuth } from '../../hook/useAuth';
import { CardFromServer } from '../../types/models/Cards';
import { Other } from '../../types/models/Other';
import { formats } from '../../constants/formats';
import colors from '../../constants/colors';
import Button, { buttonType } from '../../components/general/Button';
import ReactSelect from 'react-select';
import Loader from '../../components/general/Loader';
import {
  createCardByAdmin,
  getCardByIDorSKU,
  getLastSKUTaken,
} from '../../api/admin';

export const NewCreationPage = () => {
  const [traduction, setTraduction] = useState<'FR' | 'DE' | 'IT' | null>(null);
  const [isTraduction, setIsTraduction] = useState<boolean>(false);
  const [isVariation, setIsVariation] = useState<boolean>(false);
  const [skuSearched, setSkuSearched] = useState<string>('');

  const [formatChosen, setFormatChosen] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorValue, setErrorValue] = useState<string>('');
  const [successValue, setSuccessValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const [nextSKU, setNextSKU] = useState<string>('');

  const handleSearch = async () => {
    setIsLoading(true);
    const res = await getCardByIDorSKU(
      skuSearched,
      isTraduction ? traduction : null,
    );

    if (res.status === 409 || res.status === 404) {
      setSuccessMessage('');
      setSuccessValue('');
      setErrorMessage(res.response.message);
      setErrorValue(res.response.error ?? '');
      setNextSKU('');
    } else {
      setErrorMessage('');
      setErrorValue('');
      setSuccessMessage('Le modèle de référence a bien été trouvé.');
      setSuccessValue(res.id);
      console.log(res);
      if (isTraduction) {
        setNextSKU(
          skuSearched.split('-').slice(0, -1).join('-') + '-' + traduction,
        );
      } else {
        setNextSKU(
          (parseInt(res.lastSKU.split('-')[0]) + 1).toString() +
            '-' +
            skuSearched.split('-')[1] +
            '-' +
            traduction,
        );
      }
    }

    setIsLoading(false);
  };

  const getLastSKU = async () => {
    setIsLoading(true);
    const res = await getLastSKUTaken();
    console.log(res);
    setNextSKU(
      (parseInt(res.split('-')[0]) + 1).toString() +
        '-' +
        formatChosen +
        '-' +
        traduction,
    );
    setIsLoading(false);
  };

  const startCreation = async () => {
    const res = await createCardByAdmin(
      nextSKU,
      successValue === '' ? undefined : successValue,
    );

    console.log(res);

    window.location.replace('https://my.planet-cards.com/carte/' + res);
  };

  return (
    <Container>
      <Title>Création d'un nouveau modèle</Title>
      <Wrapper>
        <PartContainer>
          <Subtitle>A partir d'un autre modèle</Subtitle>
          <TextInputContainer>
            ID ou SKU du modèle à reprendre
            <TextInput
              value={skuSearched}
              onChange={(e) => setSkuSearched(e.target.value)}
            />
          </TextInputContainer>
          <ButtonContainer>
            <Button
              $type={isVariation ? buttonType.black : buttonType.white}
              onClick={() => {
                setIsVariation(true);
                setIsTraduction(false);
              }}
            >
              Variation
            </Button>
            <Button
              $type={isTraduction ? buttonType.black : buttonType.white}
              onClick={() => {
                setIsVariation(false);
                setIsTraduction(true);
              }}
            >
              Traduction
            </Button>
          </ButtonContainer>
          <TextInputContainer>
            Langue
            <ButtonContainer>
              <Button
                $type={
                  traduction === 'FR' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('FR')}
              >
                FR
              </Button>
              <Button
                $type={
                  traduction === 'DE' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('DE')}
              >
                DE
              </Button>
              <Button
                $type={
                  traduction === 'IT' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('IT')}
              >
                IT
              </Button>
            </ButtonContainer>
          </TextInputContainer>
          <ButtonContainer style={{ marginTop: '12px' }}>
            <Button $type={buttonType.themed} onClick={handleSearch}>
              {isLoading ? <Loader /> : 'Chercher'}
            </Button>
          </ButtonContainer>
        </PartContainer>
        <Line />
        <PartContainer>
          <Subtitle>De zéro</Subtitle>
          <TextInputContainer>
            Format
            <ReactSelect
              options={Object.keys(formats)
                .filter(
                  (format) =>
                    !format.includes('_old') && !format.includes('MAG'),
                )
                .map((key) => ({ label: key, value: key }))}
              styles={{
                menuList: (styles) => {
                  return {
                    ...styles,
                    position: 'absolute',
                    width: '100%',
                  };
                },
                container: (styles) => {
                  return {
                    ...styles,
                    width: '100%',
                    minHeight: '42px',
                  };
                },
                option: (styles, { data, isSelected, isFocused }) => {
                  return {
                    ...styles,
                    color: colors.black,
                    backgroundColor: isSelected
                      ? colors.gray300
                      : isFocused
                      ? colors.gray100
                      : colors.white,
                  };
                },
                singleValue: (styles, { data }) => {
                  return {
                    ...styles,
                    color: colors.black,
                  };
                },
              }}
              onChange={(newValue) => {
                if (newValue) {
                  setFormatChosen(newValue.value);
                } else {
                  setFormatChosen('');
                }
              }}
            />
          </TextInputContainer>
          <TextInputContainer>
            Langue
            <ButtonContainer>
              <Button
                $type={
                  traduction === 'FR' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('FR')}
              >
                FR
              </Button>
              <Button
                $type={
                  traduction === 'DE' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('DE')}
              >
                DE
              </Button>
              <Button
                $type={
                  traduction === 'IT' ? buttonType.black : buttonType.white
                }
                onClick={() => setTraduction('IT')}
              >
                IT
              </Button>
            </ButtonContainer>
          </TextInputContainer>
          <ButtonContainer style={{ marginTop: '12px' }}>
            <Button $type={buttonType.themed} onClick={getLastSKU}>
              {isLoading ? <Loader /> : 'Chercher'}
            </Button>
          </ButtonContainer>
        </PartContainer>
      </Wrapper>
      {errorMessage !== '' && (
        <Subtitle style={{ color: colors.error700 }}>{errorMessage}</Subtitle>
      )}
      {successMessage !== '' && (
        <Subtitle style={{ color: colors.success }}>{successMessage}</Subtitle>
      )}
      {(successValue !== '' || errorValue !== '') && (
        <StyledLink
          href={
            location.href.split('/')[0] +
            '/carte/' +
            (successValue === '' ? errorValue : successValue)
          }
        >
          Voir le modèle
        </StyledLink>
      )}
      {nextSKU !== '' && (
        <Subtitle style={{ color: colors.success700 }}>
          Le SKU créé sera : {nextSKU}
        </Subtitle>
      )}
      <TextInputContainer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          $type={buttonType.themed}
          disabled={nextSKU === ''}
          onClick={startCreation}
        >
          Démarrer
        </Button>
      </TextInputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  color: ${colors.black};

  margin: 24px 0 12px 0;
`;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const PartContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Line = styled.div`
  width: 1px;
  height: 200px;
  background-color: ${colors.black};
`;

const TextInputContainer = styled.div`
  width: 50%;
  gap: 2px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 12px 14px;
  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  box-sizing: border-box;

  margin: 0 0 20px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  gap: 12px;
`;

const StyledLink = styled.a`
  width: fit-content;
  display: flex;
  text-align: center;
  cursor: pointer;
  color: ${colors.black};
  text-decoration: none;
  font-size: 16px;
  border: solid 1px ${colors.black};
  border-radius: 8px;
  padding: 6px;
`;
