import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  openMenu,
  openModal,
  setFocus,
} from '../../constants/initialStore';
import { AlbumPage } from '../../types/models/Album';
import styled from 'styled-components';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { AlbumType, albumFormats } from '../../constants/albumFormats';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import useElementDrop from '../../hook/useElementDrop';
import colors from '../../constants/colors';
import { mergeRefs } from 'react-merge-refs';
import { ElementType } from '../../types/models/Elements';
import TextElement from '../../components/cardElements/textElements/TextElement';
import PhotoElement from '../../components/cardElements/photoElements/PhotoElement';
import MotifElement from '../../components/cardElements/motifElements/MotifElement';
import {
  AlbumTitle,
  PageContainer,
  SliceContainer,
  SpiraleContainer,
  Title,
} from './AlbumPageStyles';
import { useTranslation } from 'react-i18next';
import ChangeNameModal from '../../components/layout/modals/ChangeNameModal';
import { saveNameAlbum } from '../../api/album';
import GridOverlay from '../../components/functionnalities/GridOverlay';

export const Slice = ({
  id,
  backgroundColor,
  backgroundTheme,
  children,
}: Pick<AlbumPage, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
}) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.ratio!.value);
  const { height } = albumFormats[format];
  const displayWidth = ConvertMMToPixel(20) * ratio;
  const displayHeight = ConvertMMToPixel(height) * ratio;
  const dispatch = useDispatch();

  const element = useSelector((state: RootState) =>
    state.creation.present.elements.value.find(
      (elmt) => elmt.isForSlice === true,
    ),
  );

  return (
    <SliceContainer
      $width={displayWidth}
      $height={displayHeight}
      $backgroundColor={backgroundColor ?? ''}
      $backgroundTheme={backgroundTheme}
      $ratio={ratio}
    >
      {element && (
        <SliceTextContainer $displayWidth={displayWidth}>
          <TextElement key={element.id} element={element} index={0} />
        </SliceTextContainer>
      )}
    </SliceContainer>
  );
};

const Spirale = () => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.ratio!.value);
  const { height } = albumFormats[format];
  const displayWidth = ConvertMMToPixel(20) * ratio;
  const displayHeight = ConvertMMToPixel(height) * ratio;
  const dispatch = useDispatch();

  return (
    <SpiraleContainer
      $width={displayWidth}
      $height={displayHeight}
      $ratio={ratio}
      src={'/spirale.png'}
    />
  );
};

const Page = ({
  id,
  backgroundColor,
  backgroundTheme,
  children,
  title,
  coverID,
}: Pick<AlbumPage, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
  title: string;
  coverID?: string;
}) => {
  const dispatch = useDispatch();

  const isFocus = useSelector((state: RootState) => state.focus.value === id);
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.ratio!.value);
  const { canEditBackCover, width, height } = albumFormats[format];
  const displayWidth = ConvertMMToPixel(width) * ratio;
  const displayCutZone = useSelector((state: RootState) => state.cutZone.value);
  const gridDisplay = useSelector((state: RootState) => state.grid.value);

  const elRef = useRef(null);

  const pageElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter((el) => el.pageID === id),
    { equalityFn: shallowEqual },
  );

  const drop = useElementDrop({ pageID: id });

  const onClick = () => {
    dispatch(setFocus(!canEditBackCover && coverID ? coverID : id));
    dispatch(openMenu(4));
  };

  return (
    <CoverContainer>
      <PageBorder $active={isFocus}>
        <PageContainer
          $width={displayWidth}
          $height={ConvertMMToPixel(height) * ratio}
          $backgroundColor={backgroundColor ?? ''}
          $backgroundTheme={backgroundTheme}
          $displaycutzone={displayCutZone}
          $ratio={ratio}
          ref={mergeRefs([elRef, drop])}
          onClick={onClick}
        >
          {gridDisplay && <GridOverlay />}
          {children}
          {pageElements.map((element, index) => {
            switch (element.type) {
              case ElementType.TEXT:
                if (!element.isForSlice) {
                  return (
                    <TextElement
                      key={element.id}
                      element={element}
                      index={index}
                    />
                  );
                } else {
                  return null;
                }
              case ElementType.PHOTO:
                console.log('photo', element);
                return (
                  <PhotoElement
                    key={element.id}
                    element={element}
                    index={index}
                  />
                );
              case ElementType.MOTIF:
                return (
                  <MotifElement
                    key={element.id}
                    element={element}
                    index={index}
                  />
                );
            }
          })}
        </PageContainer>
      </PageBorder>
      <Title>{title}</Title>
    </CoverContainer>
  );
};

const CoverDisplayer = ({
  cover,
  backCover,
  $viewPerPage,
  $isAtTheEnd,
}: {
  cover: AlbumPage;
  backCover: AlbumPage;
  $viewPerPage: boolean;
  $isAtTheEnd?: boolean;
}) => {
  const [changeNameModalVisible, setChangeNameModalVisible] = useState(false);
  const dispatch = useDispatch();

  const creation = useSelector(
    (state: RootState) => state.creation.present.album,
  );
  const name = useSelector(
    (state: RootState) => state.creation.present.album!.name,
  );
  const type = useSelector(
    (state: RootState) =>
      albumFormats[state.creation.present.album!.format].type,
  );
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const albumID = useSelector(
    (state: RootState) => state.creation.present.album!.id,
  );

  const handleChangeName = async (newName: string) => {
    if (albumID) {
      await saveNameAlbum(albumID, newName);
    }
    setChangeNameModalVisible(false);
  };

  const onClickCover = () => {
    dispatch(setFocus(cover.id));
    dispatch(openMenu(4));
  };

  const onClickBackCover = () => {
    dispatch(setFocus(backCover.id));
    dispatch(openMenu(4));
  };

  const { t } = useTranslation(['common']);

  return (
    <AlbumPageContainer
      id={
        $viewPerPage && $isAtTheEnd
          ? 'creationElementPage-' + creation?.albumPages.length
          : 'creationElement--1'
      }
      $hasSpiral={type === AlbumType.SPIRALE}
      $viewPerPage={$viewPerPage}
    >
      <ChangeNameModal
        visible={changeNameModalVisible}
        setVisible={setChangeNameModalVisible}
        initialName={name ?? ''}
        handleValidate={handleChangeName}
      />
      {(!$viewPerPage || $isAtTheEnd) && (
        <Page
          backgroundColor={cover.backgroundColor}
          backgroundTheme={cover.backgroundTheme}
          id={backCover.id}
          title={t('album.backCover')}
          coverID={cover.id}
        />
      )}
      {type === AlbumType.RIGIDE && !$isAtTheEnd && <Slice {...cover} />}
      {type === AlbumType.SPIRALE && !$viewPerPage && <Spirale />}
      {(!$viewPerPage || !$isAtTheEnd) && (
        <Page {...cover} title={t('album.cover')} />
      )}
    </AlbumPageContainer>
  );
};

const AlbumPageContainer = styled.div<{
  $hasSpiral: boolean;
  $viewPerPage: boolean;
}>`
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.$hasSpiral || props.$viewPerPage ? '2px' : '12px')};
`;

const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const PageBorder = styled.div<{ $active: boolean }>`
  border: solid 1px
    ${({ $active }) => ($active ? colors.gray400 : 'transparent')};
`;

const SliceTextContainer = styled.div<{ $displayWidth: number }>`
  transform: translateY(${(props) => props.$displayWidth}px) rotate(90deg);
  transform-origin: 100% 0%;
  & > div {
    & > div {
      align-content: center;
    }
  }
`;

export default CoverDisplayer;
