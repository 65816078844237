import { styled } from 'styled-components';
import SavingPopup from '../../components/functionnalities/SavingPopup';
import {
  HorizontalTab,
  HorizontalTabContainer,
} from '../../components/general/HorizontalTab';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, changeRatio } from '../../constants/initialStore';
import CalendarMonth, { CalendarCover } from './CalendarMonth';
import { useFetchOrCreateCalendar } from '../../hook/useFetchOrCreateCalendar';
import { calendarFormats } from '../../constants/calendarFormats';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import TransformZoom from '../../components/general/TransformZoom';
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { useAutoSaveCalendar } from '../../hook/useAutoSaveCalendar';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import CalendarMonthStartModal from '../../components/functionnalities/modals/CalendarMonthStartModal';
import { useTranslation } from 'react-i18next';
import useLittleScreen from '../../hook/useLittleScreen';
import colors from '../../constants/colors';

const INITIAL_PADDING_X = 170;
const INITIAL_PADDING_X_MOBILE = 14;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.gray100};

  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const CalendarViewSwitcher = styled.div`
  display: flex;
`;

const CalendarMonths = styled.div`
  display: grid;
  grid-template-columns: '1fr';
  gap: 0 24px;
  margin-top: 36px;
  margin-bottom: 36px;
`;

const MainCalendarZone: React.FC = () => {
  const dispatch = useDispatch();
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const isMenuOpen = useSelector((state: RootState) => !!state.menu.value);
  const [view, setView] = useState<'page' | 'global'>('global');
  const [shouldShowMonthStartModal, setShouldShowMonthStartModal] =
    useState(false);
  const elRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);
  const format = calendar?.format;
  const formatObj = format && calendarFormats[format];
  const noCover = formatObj && formatObj.noCover;

  const months = calendar?.calendarMonths;

  const { t } = useTranslation(['common']);

  const littleScreen = useLittleScreen();

  useAutoSaveCalendar();

  useEffect(() => {
    if (!formatObj) return;
    const { width, height } = formatObj;

    const { clientHeight: zoneHeight, clientWidth: zoneWidth } = elRef.current!;

    let ratio = 0;
    if (view === 'page') {
      ratio = Math.min(
        (zoneWidth -
          2 * (littleScreen ? INITIAL_PADDING_X_MOBILE : INITIAL_PADDING_X)) /
          ConvertMMToPixel(width),
        200000,
        // zoneHeight / ConvertMMToPixel(height),
      );
    } else if (view === 'global') {
      ratio = Math.min(
        (zoneWidth -
          2 * (littleScreen ? INITIAL_PADDING_X_MOBILE : INITIAL_PADDING_X) -
          24) /
          2 /
          ConvertMMToPixel(width),
        200000,
        // zoneHeight / ConvertMMToPixel(height),
      );
    }
    ratio = Math.max(ratio, 0.01);
    dispatch(changeRatio(ratio));
  }, [view, formatObj, isMenuOpen]);

  useEffect(() => {
    if (littleScreen) {
      setView('page');
    }
  }, [littleScreen]);

  return (
    <Container ref={elRef} id="calendarZone">
      {!littleScreen && (
        <CalendarViewSwitcher>
          <HorizontalTabContainer>
            <HorizontalTab
              $active={view === 'global'}
              onClickCapture={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setView('global');
              }}
            >
              {t('calendar.globalView')}
            </HorizontalTab>
            <HorizontalTab
              $active={view === 'page'}
              onClickCapture={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setView('page');
              }}
            >
              {t('calendar.pageView')}
            </HorizontalTab>
          </HorizontalTabContainer>
        </CalendarViewSwitcher>
      )}
      <SavingPopup />
      <TransformZoom
        ref={zoomRef}
        contentStyle={{
          width: '100%',
          alignItems: 'center',
        }}
        view={view}
      >
        {months && (
          <CalendarMonths>
            {!noCover && <CalendarCover {...months[0]} />}
            {months.slice(1).map((month, index) => {
              if (view === 'page') {
                return (
                  <CalendarMonth
                    IDToUse={'creationElementPage-' + index}
                    key={index}
                    {...month}
                    index={index}
                    useID={true}
                  />
                );
              } else {
                if (index % 2 === 0) {
                  return (
                    <div
                      id={'creationElement-' + index / 2}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '36px',
                      }}
                    >
                      <CalendarMonth
                        key={index}
                        {...month}
                        index={index}
                        useID={false}
                      />
                      <CalendarMonth
                        key={index + 1}
                        {...months.slice(1)[index + 1]}
                        index={index + 1}
                        useID={false}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              }
            })}
          </CalendarMonths>
        )}
      </TransformZoom>
      <CalendarMonthStartModal
        visible={shouldShowMonthStartModal}
        setVisible={setShouldShowMonthStartModal}
      />
    </Container>
  );
};

export default MainCalendarZone;
