import { useEffect } from 'react';
import { LoginState, useAuth } from './useAuth';
import { useDispatch } from 'react-redux';
import {
  changeAlbum,
  changeCard,
  endLoading,
  loadElements,
  store,
} from '../constants/initialStore';
import { v4 as uuid } from 'uuid';
import {
  redirect,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AlbumType, albumFormats } from '../constants/albumFormats';
import { createAlbum, getOngoingAlbum } from '../api/album';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import {
  ElementType,
  PhotoElementStyle,
  TextElementStyle,
} from '../types/models/Elements';
import { defaultElementsOnPage } from '../constants/defaultElementOnPage';
import colors from '../constants/colors';

export const fetchAlbum = async (id?: string) => {
  const album = await getOngoingAlbum(id);
  if (!album) return redirect('/404');
  album.albumCover = album.albumPages.find((page) => page.position === -1) ?? {
    id: uuid(),
    backgroundColor: '#ffffff',
    backgroundTheme: undefined,
    position: -1,
    pageElements: [],
  };
  album.albumBackCover = album.albumPages.find(
    (page) => page.position === -2,
  ) ?? {
    id: uuid(),
    backgroundColor: '#ffffff',
    backgroundTheme: undefined,
    position: -2,
    pageElements: [],
  };
  store.dispatch(
    loadElements(
      album.albumPages.flatMap((page) => {
        console.log(page.pageElements);
        return page.pageElements.map(({ style, ...element }) => ({
          style: JSON.parse(style as unknown as string),
          ...element,
        }));
      }),
    ),
  );
  album.albumPages = album.albumPages.filter((page) => page.position >= 0);
  store.dispatch(changeAlbum(album));

  store.dispatch(endLoading());

  return album;
};

export const useFetchOrCreateAlbum = () => {
  const { userInfo, isReady, useNewAccessToken } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const createAlbumFn = async () => {
    const formatAsked =
      searchParams.get('format') || Object.keys(albumFormats)[0];
    const languageAsked = searchParams.get('lng') || 'fr';
    const fromCart = searchParams.get('fromCart') || false;
    const format = albumFormats[formatAsked];
    const { width: mmWidth, height: mmHeight } = format;
    const width = ConvertMMToPixel(mmWidth);
    const height = ConvertMMToPixel(mmHeight);
    const coverID = uuid();
    const backCoverID = uuid();

    const albumCoverPageElements = format.defaultCoverPage
      ? defaultElementsOnPage[format.defaultCoverPage](width, height)
      : defaultElementsOnPage['oneWindowPhoto'](width, height);

    const backCoverPageElements = format.canEditBackCover
      ? albumCoverPageElements.map((pageElement) => ({
          ...pageElement,
          id: uuid(),
        }))
      : [];

    const payload = {
      format: formatAsked,
      name: 'Mon album photo',
      albumPages: Array.from(
        {
          length: format.minPages,
        },
        (_, index) => {
          const pageID = uuid();
          return {
            id: pageID,
            backgroundColor: '#ffffff',
            backgroundTheme: undefined,
            position: index,
            pageElements: [
              {
                type: ElementType.PHOTO,
                id: uuid(),
                pageID: pageID,
                content: '',
                width: width * 0.8,
                height: height * 0.725,
                top: height * 0.1375,
                left: width * 0.1,
                zIndex: 0,
                editable: false,
                contentEditable: true,
                style: JSON.stringify({
                  brightness: 1,
                  saturation: 100,
                  opacity: 1,
                  zoom: 100,
                  rotation: 0,
                  grayscale: false,
                  sepia: false,
                  mirrored: false,
                  shadow: false,
                  shape: 'rectangle',
                  translateX: 0,
                  translateY: 0,
                  rotationFrame: 0,
                }) as unknown as TextElementStyle | PhotoElementStyle,
              },
            ],
          };
        },
      ),
      albumCover: {
        id: coverID,
        backgroundColor: '#ffffff',
        backgroundTheme: undefined,
        position: -1,
        pageElements: albumCoverPageElements
          .map((pageElement) => ({
            ...pageElement,
            pageID: coverID,
          }))
          .concat([
            {
              id: uuid(),
              type: ElementType.TEXT,
              pageID: coverID,
              content: '',
              editable: false,
              contentEditable: true,
              width: height,
              height: ConvertMMToPixel(20),
              top: 0,
              left: 0,
              zIndex: 0,
              isForSlice: true,
              style: JSON.stringify({
                bold: false,
                italic: false,
                underlined: false,
                size: 12,
                letterSpacing: 0,
                interline: 1,
                alignement: 'center',
                font: 'DM Sans',
                color: colors.black,
                transform: 'none',
                rotation: 0,
              }) as unknown as TextElementStyle,
            },
          ]),
      },
      albumBackCover: {
        id: backCoverID,
        backgroundColor: '#ffffff',
        backgroundTheme: undefined,
        position: -2,
        pageElements: backCoverPageElements.map((pageElement) => ({
          ...pageElement,
          pageID: backCoverID,
        })),
      },
    };
    dispatch(
      changeAlbum({
        ...payload,
      }),
    );
    const updatedPayload = {
      ...payload,
      albumPages: payload.albumPages.concat([
        payload.albumCover,
        payload.albumBackCover,
      ]),
      albumCover: undefined,
      albumBackCover: undefined,
    };
    const newAlbum = await createAlbum(updatedPayload, languageAsked);
    window.location.replace(
      window.location.origin +
        `/album/${newAlbum.id}?lng=${languageAsked}${
          fromCart ? '&fromCart=true' : ''
        }`,
    );
  };

  useEffect(() => {
    if (isReady) {
      const newToken = searchParams.get('token');
      if (newToken) {
        const res = useNewAccessToken(newToken);
        if (res) {
          const urlActuelle = window.location.href;
          const urlSansToken = urlActuelle.replace(/token=[^&]*(&?)/, '');
          window.location.replace(urlSansToken);
        }
      }
    }
    if (
      window.location.pathname.includes('calendrier') ||
      window.location.pathname.includes('carte') ||
      window.location.pathname.includes('autre') ||
      window.location.pathname.includes('admin') // Route type is inferred later
    ) {
      return;
    }

    // it is here to prevent multiple creation, but the useEffect should be transformed in the future so it is not triggered so often
    if (userInfo === null) {
      return;
    }

    if (!id || (id && id === 'nouveau')) {
      createAlbumFn(); // Last creation
      return;
    }
    if (id && id !== 'nouveau') {
      fetchAlbum(id);
      return;
    }
    createAlbumFn();
  }, [userInfo, isReady, searchParams]);
};
