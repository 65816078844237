import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import colors from '../../../constants/colors';
import { styled } from 'styled-components';
import { Element, TextElementStyle } from '../../../types/models/Elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  closeMenu,
  modifyElement,
  openMenu,
  resetFocus,
  setFocus,
} from '../../../constants/initialStore';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import { TextDisplayer } from './TextDisplayer';
import { LoginState, useAuth } from '../../../hook/useAuth';
import { ReactSVG } from 'react-svg';
import Hotjar from '@hotjar/browser';
import sanitizeInput from '../../../utils/sanitizeInput';

interface TextElementProps {
  element: Element;
  index: number;
  $correctionTranslation?: number[];
}

const TextElement: React.FC<TextElementProps> = ({
  element,
  $correctionTranslation,
}) => {
  const dispatch = useDispatch();

  const [draggindDisabled, setDraggindDisabled] = useState(false);

  const focus = useSelector((state: RootState) => state.focus.value);
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const zoom = useSelector((state: RootState) => state.zoom.value);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  const [isEditing, setIsEditing] = useState<boolean>(focus === element.id);

  const textInputRef = useRef(null);

  const { t } = useTranslation(['common']);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const concernedElement = document.getElementById(
        'textInput-' + element.id,
      );
      const TextMenuElement = document.getElementById('menu');
      const RemoveModal = document.getElementById('removeModal');
      const ColorPicker = document.getElementById('colorPicker');
      const rnd = document.getElementById('rnd-' + element.id);
      const SidebarElement = document.getElementById('sidebar');
      if (
        concernedElement &&
        !concernedElement.contains(event.target) &&
        TextMenuElement &&
        !TextMenuElement.contains(event.target) &&
        SidebarElement &&
        !SidebarElement.contains(event.target) &&
        RemoveModal &&
        !RemoveModal.contains(event.target) &&
        (!ColorPicker || !ColorPicker.contains(event.target)) &&
        rnd &&
        !rnd.contains(event.target)
      ) {
        if (focus === element.id) {
          concernedElement.blur();
          dispatch(resetFocus());
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [focus, element.id]);

  const handleClickInside: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const input = document.getElementById('textInput-' + element.id)!;

    input.focus();

    dispatch(setFocus(element.id));
    dispatch(openMenu(2)); // 2 = Menu de texte
    Hotjar.event('Appuie sur une zone texte');
  };

  const handleClickInside2: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const input = document.getElementById('textInput-' + element.id)!;

    input.focus();

    dispatch(setFocus(element.id));
    dispatch(openMenu(2)); // 2 = Menu de texte
    Hotjar.event('Appuie sur une zone texte');
  };

  const handleChangeText = (event: ContentEditableEvent) => {
    dispatch(
      modifyElement({
        id: element.id,
        element: { ...element, content: event.target.value },
      }),
    );
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const pastedText =
      event.clipboardData.getData('text/html') ||
      event.clipboardData.getData('text/plain');
    const sanitizedText = sanitizeInput(pastedText);

    dispatch(
      modifyElement({
        id: element.id,
        element: { ...element, content: sanitizedText },
      }),
    );
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Delete' && e.key !== 'Backspace') return;
    e.stopPropagation();
  };

  useEffect(() => {
    setHeight(element.height);
    setWidth(element.width);
    setTop(element.top);
    setLeft(element.left);
  }, [element]);

  useEffect(() => {
    if (focus !== element.id) {
      setIsEditing(false);
    }
  }, [focus]);

  const user = useAuth();
  const contentIsEditable = () => {
    const isAdmin =
      user.userInfo?.state === LoginState.LOGGED_IN && user.userInfo.isAdmin;
    return !(!element.contentEditable && !isAdmin);
  };

  return (
    <RndCustom
      scale={zoom}
      size={{
        width: width * ratio,
        height: height * ratio,
      }}
      position={{
        x:
          left * ratio +
          ($correctionTranslation ? $correctionTranslation[0] : 0),
        y:
          top * ratio +
          ($correctionTranslation ? $correctionTranslation[1] : 0),
      }}
      bounds="body"
      onResize={(event: any) => {
        event.stopPropagation();
        document.getElementById('textInput-' + element.id)?.focus();
        dispatch(setFocus(element.id));
        setDraggindDisabled(true);
      }}
      onDrag={(event: any) => {
        event.stopPropagation();
        document.getElementById('textInput-' + element.id)?.focus();
        dispatch(setFocus(element.id));
      }}
      onDragStop={(e: any, d: any) => {
        if (draggindDisabled) return;
        dispatch(
          modifyElement({
            id: element.id,
            element: { ...element, top: d.y / ratio, left: d.x / ratio },
          }),
        );
        dispatch(openMenu(2)); // 2 = Menu de texte
      }}
      onResizeStop={(e: any, direction: any, ref: any) => {
        dispatch(
          modifyElement({
            id: element.id,
            element: {
              ...element,
              width: parseFloat(ref.style.width.slice(0, -2)) / ratio,
              height: parseFloat(ref.style.height.slice(0, -2)) / ratio,
            },
          }),
        );
        dispatch(openMenu(2)); // 2 = Menu de texte
        setDraggindDisabled(false);
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: element.editable,
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={!element.editable}
      dragHandleClassName="dragHandle"
      $active={focus === element.id}
      $index={element.zIndex}
      $canClick={contentIsEditable()}
      onClick={handleClickInside}
      cancel=".textElement"
      style={{ display: 'flex' }}
      id={'rnd-' + element.id}
    >
      <DragHandle
        $active={focus === element.id && element.editable}
        className="dragHandle"
      >
        <div>
          <ReactSVG
            src="/svg/move.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 12px; height: 12px;');
            }}
            style={{
              display: 'flex',
              position: 'relative',
              top: '-2px',
              left: '2px',
            }}
          />
        </div>
      </DragHandle>
      <TextInput
        id={'textInput-' + element.id}
        html={element.content
          .replace(/<span[^>]*>/gi, '')
          .replace(/<\/span>/gi, '')} // Remove span balises
        className="textElement"
        onChange={handleChangeText}
        disabled={false}
        spellCheck={false}
        data-placeholder={t('text.enterTextHere')}
        $elementstyle={'bold' in element.style ? element.style : null}
        $ratio={ratio}
        onKeyDown={onKeyDown}
        onClick={handleClickInside2}
        onPaste={handlePaste}
      />
      {/* <SmallSquare $position="nw" className="square" />
      <SmallSquare $position="ne" className="square" /> */}
      <SmallSquare $position="se" className="square" />
      {/* <SmallSquare $position="sw" /> */}
    </RndCustom>
  );
};

const RndCustom = styled(Rnd)<{
  $active: boolean;
  $index: number;
  $canClick: boolean;
}>`
  & > .square {
    display: ${(props) => (props.$active ? 'flex' : 'none')};
  }

  display: flex;
  overflow: visible;
  flex: wrap;

  z-index: ${(props) => props.$index};

  ${(props) => (props.$canClick ? '' : 'pointer-events: none;')}
  &:after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    border: solid 2px
      ${(props) => (props.$active ? colors.gray400 : 'transparent')};
    pointer-events: none;
  }

  &:hover {
    &:after {
      content: '';
      position: absolute;
      inset: -1px;
      border-radius: inherit;
      border: solid 1px
        ${(props) => (props.$active ? colors.gray400 : colors.gray200)};
      pointer-events: none;
    }
  }
`;

const DragHandle = styled.div<{
  $active: boolean;
}>`
  display: ${(props) => (props.$active ? 'flex' : 'none')};
  position: absolute;

  justify-content: center;
  padding-top: 2px;

  width: 26px;
  height: 26px;
  background-color: transparent;

  border-radius: 13px;

  top: -13px;
  left: -13px;
  cursor: move;
  z-index: 100;

  & > div {
    position: relative;

    background-color: ${colors.white};

    width: 18px;
    height: 18px;
    border: solid 1px ${colors.gray400};

    border-radius: 9px;
  }
`;

const TextInput = styled(ContentEditable)<{
  $elementstyle: TextElementStyle | null;
  $ratio: number;
}>`
  position: relative;
  flex: 1;
  ${TextDisplayer};

  & > div {
    pointer-events: none;
  }

  & > span {
    font-size: inherit;
    letter-spacing: inherit;
  }
`;

const SmallSquare = styled.div<{ $position: 'nw' | 'ne' | 'se' | 'sw' }>`
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  pointer-events: none;
  background-color: ${colors.white};
  border: solid 1px ${colors.gray400};
  box-sizing: border-box;
  top: ${(props) =>
    props.$position === 'nw' || props.$position === 'ne' ? '-6px' : 'auto'};
  bottom: ${(props) =>
    props.$position === 'sw' || props.$position === 'se' ? '-6px' : 'auto'};
  left: ${(props) =>
    props.$position === 'nw' || props.$position === 'sw' ? '-6px' : 'auto'};
  right: ${(props) =>
    props.$position === 'se' || props.$position === 'ne' ? '-6px' : 'auto'};
  z-index: 80;
`;

export default TextElement;
