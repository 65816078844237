import axios from 'axios';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppRoutes from './routing/AppRoutes';
import { AuthProvider } from './hook/useAuth';
import { Provider } from 'react-redux';
import { store } from './constants/initialStore';
import './i18n';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { initGA, logPageView } from './analytics';

const measurementID = 'G-3BYMN9F3TP'; // Replace with your measurement ID

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
};

function App() {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL;

  // Sentry.init({
  //   dsn: 'https://d92f056aa73dc1168a3f475b235a03f8@o1228528.ingest.us.sentry.io/4507095355293696',
  //   integrations: [Sentry.browserTracingIntegration()],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: [
  //     /^https:\/\/staging-my\.planet-cards\./,
  //     /^https:\/\/my\.planet-cards\./,
  //   ],
  // });

  // useEffect(() => {
  //   initGA(measurementID);
  // }, []);

  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <PageViews />
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

const PageViews: React.FC = () => {
  usePageViews();
  return null;
};

export default App;
