import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeAlbum,
  openMenu,
  removePages,
  setFocus,
} from '../../constants/initialStore';
import { v4 as uuid } from 'uuid';
import { AlbumPage } from '../../types/models/Album';
import styled from 'styled-components';
import { ReactNode, useEffect, useRef } from 'react';
import { AlbumType, albumFormats } from '../../constants/albumFormats';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import useElementDrop from '../../hook/useElementDrop';
import colors from '../../constants/colors';
import { mergeRefs } from 'react-merge-refs';
import { ElementType } from '../../types/models/Elements';
import TextElement from '../../components/cardElements/textElements/TextElement';
import PhotoElement from '../../components/cardElements/photoElements/PhotoElement';
import MotifElement from '../../components/cardElements/motifElements/MotifElement';
import { ReactSVG } from 'react-svg';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import {
  AlbumPageContainer,
  DoubleSeparator,
  PageContainer,
  Title,
} from './AlbumPageStyles';
import { useTranslation } from 'react-i18next';

export const PageRemover = ({ index }: { index: number }) => {
  const album = useSelector((state: RootState) => state.creation.present.album);
  const dispatch = useDispatch();

  const removePage = () => {
    if (!album) return;
    dispatch(
      removePages({
        index:
          index < album.albumPages.length - 4
            ? index
            : album.albumPages.length - 4,
        count: 4,
      }),
    );
  };

  return (
    <RemoverContainer
      onClick={() => removePage()}
      title="Supprimer la double page"
    >
      <ReactSVG
        src="/svg/trash_white.svg"
        beforeInjection={(svg) => {
          svg.setAttribute(
            'style',
            'width: 15px; height: 15px; margin-top: 4px;',
          );
        }}
      />
    </RemoverContainer>
  );
};

export const Page = (
  props:
    | (Pick<
        AlbumPage,
        'id' | 'backgroundColor' | 'backgroundTheme' | 'position'
      > & {
        children?: ReactNode;
        isCover?: boolean;
        isGeneration?: boolean;
      })
    | { isTransparent: boolean },
) => {
  const dispatch = useDispatch();

  const album = useSelector((state: RootState) => state.creation.present.album);
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.ratio!.value);
  const { width, height } = albumFormats[format];
  const isDouble = albumFormats[format].type === AlbumType.PLAT;
  const displayWidth = (isDouble ? 2 : 1) * ConvertMMToPixel(width) * ratio;
  const displayCutZone = useSelector((state: RootState) => state.cutZone.value);
  const gridDisplay = useSelector((state: RootState) => state.grid.value);

  if ('isTransparent' in props) {
    return (
      <PageBorder $active={false}>
        <PageContainer
          $width={displayWidth}
          $height={ConvertMMToPixel(height) * ratio}
          $backgroundColor={''}
          $backgroundTheme={undefined}
          $displaycutzone={displayCutZone}
          $ratio={ratio}
          style={{
            backgroundColor: 'transparent',
            pointerEvents: 'none',
            border: 'dashed 1px ' + colors.gray700,
          }}
        ></PageContainer>
      </PageBorder>
    );
  }

  const isFocus = useSelector(
    (state: RootState) => state.focus.value === props.id,
  );
  const index = album?.albumPages.findIndex((page) => page.id === props.id);

  const elRef = useRef(null);

  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );

  const pageElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter(
        (el) => el.pageID === props.id,
      ),
    { equalityFn: shallowEqual },
  );

  const drop = useElementDrop({ pageID: props.id });

  const onClick = () => {
    dispatch(setFocus(props.id));
    dispatch(openMenu(4));
  };

  return (
    <PageBorder $active={isFocus}>
      <PageContainer
        $width={displayWidth}
        $height={ConvertMMToPixel(height) * ratio}
        $backgroundColor={props.backgroundColor ?? ''}
        $backgroundTheme={props.backgroundTheme}
        $displaycutzone={displayCutZone}
        $ratio={ratio}
        ref={mergeRefs([elRef, drop])}
      >
        {props.children}
        {gridDisplay && <GridOverlay />}
        {pageElements.map((element, index) => {
          switch (element.type) {
            case ElementType.TEXT:
              if (
                (element.content === '' && props.isGeneration) ||
                element.isForSlice
              ) {
                return null;
              }
              return (
                <TextElement key={element.id} element={element} index={index} />
              );
            case ElementType.PHOTO:
              return (
                <PhotoElement
                  key={element.id}
                  element={element}
                  index={index}
                />
              );
            case ElementType.MOTIF:
              return (
                <MotifElement
                  key={element.id}
                  element={element}
                  index={index}
                />
              );
          }
        })}
        {props.isCover && (
          <LogoIcon src="/logo_pp_album.svg" $width={displayWidth * 0.1} />
        )}
      </PageContainer>
    </PageBorder>
  );
};

export const AlbumPageDisplayer = (props: AlbumPage) => {
  const dispatch = useDispatch();
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );

  const isDouble = albumFormats[format].type === AlbumType.PLAT;

  const onClick = () => {
    dispatch(setFocus(props.id));
    dispatch(openMenu(4));
  };

  const { i18n } = useTranslation();

  const displayPageTitle = (language: string) => {
    return language === 'fr'
      ? 'Page '
      : language === 'it'
      ? 'Pagina '
      : language === 'de'
      ? 'Seite '
      : 'Page ';
  };

  return (
    <AlbumPageContainer
      onClick={onClick}
      id={'creationElementPage-' + props.position}
    >
      <Page {...props}>{isDouble && <DoubleSeparator />}</Page>
      <Title>
        {displayPageTitle(i18n.language)}
        {props.position + 1}
      </Title>
    </AlbumPageContainer>
  );
};

export const TransparentPageDisplayer = () => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );

  const isDouble = albumFormats[format].type === AlbumType.PLAT;

  return (
    <AlbumPageContainer style={{ cursor: 'auto' }}>
      <Page isTransparent={true}>{isDouble && <DoubleSeparator />}</Page>
    </AlbumPageContainer>
  );
};

const PageBorder = styled.div<{ $active: boolean }>`
  position: relative;
  border: solid 1px
    ${({ $active }) => ($active ? colors.gray400 : 'transparent')};
`;

const RemoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  background-color: ${colors.white};
  border: solid 1px ${colors.error600};
  cursor: pointer;
`;

const LogoIcon = styled(ReactSVG)<{ $width: number }>`
  z-index: 200;
  width: ${(props) => props.$width}px;
  color: ${colors.black};
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
`;

export default AlbumPageDisplayer;
