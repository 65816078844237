import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../../constants/colors';
import styleConstants from '../../../constants/styleConstants';
import { PhotoFolder, PhotoType } from '../../../types/models/Photos';
import Button, { buttonType } from '../../general/Button';
import { Dimensions } from '../PhotoMenu';
import { pricePerPageMap } from '../../../pages/albums/PageAdd';
import ButtonText from '../../general/ButtonText';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  format: string;
  handleValidate: () => void;
}

const PriceInfoModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  format,
  handleValidate,
}) => {
  const { t } = useTranslation(['common']);

  return createPortal(
    <Container $show={visible} id="priceInfoModal">
      <FormContainer>
        {t('album.pagePrice') +
          pricePerPageMap[format as keyof typeof pricePerPageMap] +
          t('album.perPage')}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <Button
            $type={buttonType.themed}
            onClick={() => {
              handleValidate();
              setVisible(false);
            }}
          >
            {t('calendar.confirm')}
          </Button>
          <Button
            $type={buttonType.white}
            onClick={() => {
              setVisible(false);
            }}
          >
            {t('calendar.cancel')}
          </Button>
        </div>
      </FormContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 600px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding: 0 12px 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
    flex-wrap: wrap;
  }
`;

const FormContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  gap: 12px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  color: ${colors.gray900};
`;

const SubTitle = styled.div`
  width: 100%;
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 500;
  text-align: left;

  color: ${colors.gray900};
`;

const PhotoList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 12px 8px;
  overflow-x: scroll;
`;

const PhotoDisplayer = styled.img<{ selected: boolean }>`
  width: 128px;
  height: 112px;
  padding: 8px;
  border: solid 2px ${(props) => (props.selected ? colors.green : colors.white)};
  object-fit: contain;
  background-color: ${colors.white};
`;

const FolderDisplayer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

const FolderPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 96px;
  position: relative;
  background-color: ${colors.gray100};
`;

const FolderName = styled.div`
  width: 112px;
  display: flex;
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhotoNumber = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray700};
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: 'center';
  position: relative;
  width: 100%;
  gap: 8px;
  cursor: pointer;
`;

export default PriceInfoModal;
