import { styled } from 'styled-components';
import colors from '../../constants/colors';
import TextElement from '../../components/cardElements/textElements/TextElement';
import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, resetFocus } from '../../constants/initialStore';
import { ElementType } from '../../types/models/Elements';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { cutFinitions } from '../../constants/availableFinitions';
import useElementDrop from '../../hook/useElementDrop';
import { mergeRefs } from 'react-merge-refs';
import PhotoElement from '../../components/cardElements/photoElements/PhotoElement';
import MotifElement from '../../components/cardElements/motifElements/MotifElement';
import { otherFormats } from '../../constants/otherFormats';

interface DrawZoneProps {
  displayHeight: number;
  displayWidth: number;
  displayForbiddenZones?: boolean;
  isGeneration?: boolean;
}

const OtherDrawZone: React.FC<DrawZoneProps> = forwardRef(
  (
    {
      displayHeight,
      displayWidth,
      displayForbiddenZones = true,
      isGeneration = false,
    },
    ref,
  ) => {
    const elements = useSelector(
      (state: RootState) => state.creation.present.elements.value,
    );
    const currentFace = useSelector((state: RootState) => state.face.value);
    const creation = useSelector(
      (state: RootState) => state.creation.present.other,
    );
    const ratio = useSelector((state: RootState) => state.ratio.value);

    const dispatch = useDispatch();

    const drop = useElementDrop({ face: currentFace });

    useEffect(() => {
      dispatch(resetFocus());
    }, [currentFace]);

    return (
      <Container
        ref={mergeRefs([drop, ref])}
        id="dropZone"
        $height={displayHeight}
        $width={displayWidth}
        $ratio={ratio}
        $bgcolor={creation?.backgroundColor}
        $bgtheme={creation?.backgroundTheme}
        $isToile={creation?.format.includes('TOI')}
      >
        {elements.map((element, index) => {
          switch (element.type) {
            case ElementType.TEXT:
              if (element.content === '' && isGeneration) {
                return null;
              }
              return (
                <TextElement key={element.id} element={element} index={index} />
              );
            case ElementType.PHOTO:
              return (
                <PhotoElement
                  key={element.id}
                  element={element}
                  index={index}
                />
              );
            case ElementType.MOTIF:
              return (
                <MotifElement
                  key={element.id}
                  element={element}
                  index={index}
                />
              );
          }
        })}
        {creation &&
          displayForbiddenZones &&
          otherFormats[creation.format].forbiddenZones?.map((zone, index) => {
            return (
              <ForbiddenZone
                key={index}
                $top={ConvertMMToPixel(zone.top) * ratio}
                $left={ConvertMMToPixel(zone.left) * ratio}
                $width={ConvertMMToPixel(zone.width) * ratio}
                $height={ConvertMMToPixel(zone.height) * ratio}
                $radius={zone.radius.map(
                  (thisRadius) => ConvertMMToPixel(thisRadius) * ratio,
                )}
              />
            );
          })}
        {creation &&
          displayForbiddenZones &&
          otherFormats[creation.format].optionnalZones?.map((zone, index) => {
            return (
              <OptionnalZone
                key={index}
                $top={ConvertMMToPixel(zone.top) * ratio}
                $left={ConvertMMToPixel(zone.left) * ratio}
                $width={ConvertMMToPixel(zone.width) * ratio}
                $height={ConvertMMToPixel(zone.height) * ratio}
                $radius={zone.radius.map(
                  (thisRadius) => ConvertMMToPixel(thisRadius) * ratio,
                )}
              />
            );
          })}
      </Container>
    );
  },
);

const Container = styled.div<{
  $width: number;
  $height: number;
  $ratio: number;
  $bgcolor?: string;
  $bgtheme?: string;
  $isToile?: boolean;
}>`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${(props) =>
    props.$bgcolor && props.$bgcolor !== 'null'
      ? props.$bgcolor
      : colors.gray300};
  background-image: ${(props) =>
    props.$bgtheme && props.$bgtheme !== 'null'
      ? 'url(' + props.$bgtheme + ')'
      : 'none'};
  background-size: cover;

  position: relative;
  transform: translate(0, 0);

  box-sizing: border-box;
  border: none;
`;

const HBend = styled.div<{ $position: number }>`
  position: absolute;
  top: ${(props) => props.$position - 0.5}px;
  height: 1px;
  width: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const VBend = styled.div<{ $position: number }>`
  position: absolute;
  left: ${(props) => props.$position - 0.5}px;
  top: 0px;
  width: 1px;
  height: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const ForbiddenZone = styled.div<{
  $top: number;
  $left: number;
  $width: number;
  $height: number;
  $radius: number[];
}>`
  position: absolute;

  background-color: ${colors.gray100};

  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  border-radius: ${(props) => props.$radius.join('px ')}px;

  z-index: 250;
`;

const OptionnalZone = styled.div<{
  $top: number;
  $left: number;
  $width: number;
  $height: number;
  $radius: number[];
}>`
  position: absolute;

  background-color: ${colors.gray700};
  opacity: 0.3;

  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  border-radius: ${(props) => props.$radius.join('px ')}px;

  z-index: 250;
`;

export default OtherDrawZone;
