import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, addElement, store } from '../../constants/initialStore';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import useCreationType, { creationType } from '../../hook/useCreationType';
import useLittleScreen from '../../hook/useLittleScreen';
import styleConstants from '../../constants/styleConstants';
import { useEffect, useState } from 'react';

const DEFAULT_SIZES = ConvertMMToPixel(100);
const DEFAULT_SIZES_MOBILE = ConvertMMToPixel(100);
const DEFAULT_SIZES_LABEL = ConvertMMToPixel(20);
const DEFAULT_SIZES_OTHER = ConvertMMToPixel(100);

interface MotifItemProps {
  motif: {
    name: string;
    src: string;
    group: string;
    width: number;
    height: number;
    greyBackground: boolean;
  };
  index: number;
}

const previewOptions = {
  offsetX: 0,
  offsetY: 0,
};

const MotifItem: React.FC<MotifItemProps> = ({ motif, index }) => {
  const [isLabel, setIsLabel] = useState<boolean>(false);
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [currentFocus, setCurrentFocus] = useState<string>('');

  const elementMaxZIndex = useSelector(
    (state: RootState) => state.creation.present.elements.value.length,
  );

  const [, drag] = useDrag({
    type: 'MotifItem',
    item: { ...motif, type: 'MOTIF', index: elementMaxZIndex },
    previewOptions,
  });
  const dispatch = useDispatch();
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const currentFace = useSelector((state: RootState) => state.face.value);
  const type = useCreationType();
  const focus = useSelector((state: RootState) => state.focus.value);

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const creation = useSelector((state: RootState) => state.creation);

  const littleScreen = useLittleScreen();

  useEffect(() => {
    if (card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
      if (
        (card.facesSizes[currentFace].width ===
          card.facesSizes[currentFace].height &&
          (card.facesSizes[currentFace].width === 43 ||
            card.facesSizes[currentFace].width === 40)) ||
        card.format.includes('MAG')
      ) {
        console.log(card.format);
        setIsLabel(true);
      }
    }
  }, [card, currentFace]);

  useEffect(() => {
    const elementFocused = creation.present.elements.value.find(
      (element) => element.id === focus,
    );
    if (type === 'album') {
      let currentPageFocusIndex = creation.present.album?.albumPages.findIndex(
        ({ id }) =>
          elementFocused ? id === elementFocused.pageID : id === focus,
      );

      setCurrentFocus(
        currentPageFocusIndex === undefined || currentPageFocusIndex === -1
          ? creation.present.album!.albumBackCover.id === focus
            ? creation.present.album!.albumBackCover.id
            : creation.present.album!.albumCover.id
          : creation.present.album!.albumPages[currentPageFocusIndex].id ?? '',
      );
    }
    if (type === 'carte') {
      setCurrentFocus(card.facesSizes[currentFace].id ?? '');
    }
  }, [focus, creation, type, currentFace]);

  const addMotif = () => {
    console.log(
      'width:',
      isLabel
        ? DEFAULT_SIZES_LABEL
        : littleScreen
        ? DEFAULT_SIZES_MOBILE
        : type === 'autre'
        ? DEFAULT_SIZES_OTHER
        : DEFAULT_SIZES,
      'height:',
      isLabel
        ? DEFAULT_SIZES_LABEL
        : littleScreen
        ? DEFAULT_SIZES_MOBILE
        : type === 'autre'
        ? DEFAULT_SIZES_OTHER
        : DEFAULT_SIZES,
    );
    dispatch(
      addElement({
        element: {
          type: 'MOTIF',
          top: maxHeight / 2 - (isLabel ? 118 : 150), // centered
          left: maxWidth / 2 - (isLabel ? 86 : 350), // centered
          face: type === 'carte' ? currentFace : undefined,
          calendarMonthID: type === 'calendrier' ? focus : undefined,
          pageID: type === 'album' ? currentFocus : undefined,
          width: isLabel
            ? DEFAULT_SIZES_LABEL
            : littleScreen
            ? DEFAULT_SIZES_MOBILE
            : type === 'autre'
            ? DEFAULT_SIZES_OTHER
            : DEFAULT_SIZES,
          height: isLabel
            ? DEFAULT_SIZES_LABEL
            : littleScreen
            ? DEFAULT_SIZES_MOBILE
            : type === 'autre'
            ? DEFAULT_SIZES_OTHER
            : DEFAULT_SIZES,
          content: motif.src,
          zIndex: elementMaxZIndex,
          style: {
            rotation: 0,
          },
          editable: true,
          contentEditable: true,
        },
      }),
    );
  };

  return (
    <BG
      backgroundColor={motif.greyBackground ? colors.gray400 : colors.white}
      onClick={addMotif}
      // onDoubleClick={() => {
      //   if (littleScreen) return;
      //   dispatch(
      //     addElement({
      //       element: {
      //         type: 'MOTIF',
      //         top: 600,
      //         left: 500,
      //         face: type === 'carte' ? currentFace : undefined,
      //         calendarMonthID: type === 'calendrier' ? focus : undefined,
      //         pageID: type === 'album' ? focus : undefined,
      //         width: isLabel
      //           ? DEFAULT_SIZES_LABEL * ratio
      //           : littleScreen
      //           ? DEFAULT_SIZES_MOBILE * ratio
      //           : type === 'autre'
      //           ? DEFAULT_SIZES_OTHER * ratio
      //           : DEFAULT_SIZES * ratio,
      //         height: isLabel
      //           ? DEFAULT_SIZES_LABEL * ratio
      //           : littleScreen
      //           ? DEFAULT_SIZES_MOBILE * ratio
      //           : type === 'autre'
      //           ? DEFAULT_SIZES_OTHER * ratio
      //           : DEFAULT_SIZES * ratio,
      //         content: motif.src,
      //         zIndex: elementMaxZIndex,
      //         style: {
      //           rotation: 0,
      //         },
      //         editable: true,
      //         contentEditable: true,
      //       },
      //     }),
      //   );
      // }}
    >
      <Element ref={drag} key={index} src={motif.src} />
    </BG>
  );
};

const Element = styled.img`
  width: 82px;
  height: 82px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    min-width: 36px;
    max-height: 36px;
  }
`;

const BG = styled.button<{ backgroundColor: string }>`
  display: flex;
  width: 82px;
  height: 82px;

  overflow: hidden;

  border-radius: 8px;

  border: 1px solid ${colors.gray200};
  box-sizing: border-box;

  background-color: ${(props) => props.backgroundColor};

  @media (max-width: ${styleConstants.breakpointLg}) {
    min-width: 36px;
    max-height: 36px;
  }
`;

export default MotifItem;
