import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, desactivatePopup } from '../../constants/initialStore';
import useTheme from '../../hook/useTheme';
import { useTranslation } from 'react-i18next';

const SavingPopup: React.FC = () => {
  const [bgColor, setBgColor] = useState<string>('');

  const isPopupOpen = useSelector((state: RootState) => state.popup.value);
  const dispatch = useDispatch();

  const { color } = useTheme();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(desactivatePopup());
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isPopupOpen]);

  useEffect(() => {
    console.log(color);
    setBgColor(color);
  }, [color]);

  return isPopupOpen ? (
    <PopupContainer $color={bgColor}>
      <div>{t('general.savedCreation')}</div>
    </PopupContainer>
  ) : null;
};

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const PopupContainer = styled.div<{ $color: string }>`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => props.$color};
  padding: 5px;
  font-size: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3000;
  animation: ${fadeOut} 2.5s ease-in-out;
`;

export default SavingPopup;
