import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../../components/general/Button';
import { Register } from '../../api/auth';
import colors from '../../constants/colors';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import Checkbox from '../../components/general/Checkbox';
import { useAuth } from '../../hook/useAuth';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../constants/styleConstants';
import useTheme from '../../hook/useTheme';

const nombres = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const caracteresSpeciaux = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '-',
  '_',
  '+',
  '=',
  '{',
  '}',
  '[',
  ']',
  '|',
  '\\',
  ':',
  ';',
  '"',
  "'",
  '<',
  '>',
  ',',
  '.',
  '?',
  '/',
];

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterPage: React.FC<ModalProps> = ({ visible, setVisible }) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [CGAccepted, setCGAccepted] = useState<boolean>(false);
  const [acceptsMarketing, setAcceptsMarketing] = useState<boolean>(false);

  const [successMessageVisible, setSuccessMessageVisible] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [errorPasswordMessage, setErrorPasswordMessage] = useState<string>('');
  const [passwordColor, setPasswordColor] = useState<
    '#B42318' | '#F2C84B' | '#067647'
  >('#B42318');

  const { asklogin } = useAuth();

  const { origin } = useTheme();

  const { t } = useTranslation(['common']);

  const canSubmit = useMemo(
    () =>
      !!firstName &&
      !!lastName &&
      !!email &&
      !!password &&
      !!password2 &&
      email.includes('@') &&
      password === password2 &&
      password.length >= 8 &&
      CGAccepted,
    [firstName, lastName, email, password, password2, CGAccepted],
  );

  const handleRegistration = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault();
    const res = await Register(
      {
        firstName,
        lastName,
        email,
        password,
        acceptsMarketing,
      },
      origin,
    );
    console.log(res);
    if (res.data === 200) {
      setSuccessMessageVisible(true);
      setErrorMessage('');
    } else {
      if (!res.data) {
        setErrorMessage('Erreur inconnue');
      } else {
        setErrorMessage(res.data);
      }
    }
  };

  const gotoLogIn = async () => {
    await asklogin(true);
  };

  const gotoLoginAfterCreation = async () => {
    const emailToUse = email;
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setPassword2('');
    setCGAccepted(false);
    setAcceptsMarketing(false);
    setSuccessMessageVisible(false);
    setErrorMessage('');

    await asklogin(true, emailToUse);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const gotoCGV = async () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const gotoPP = async () => {};

  useEffect(() => {
    if (
      password.length >= 12 &&
      password !== password.toLocaleUpperCase() &&
      password !== password.toLocaleLowerCase() &&
      nombres.some((v) => password.includes(v)) &&
      caracteresSpeciaux.some((v) => password.includes(v))
    ) {
      setPasswordColor('#067647');
    } else if (password.length >= 8) {
      setPasswordColor('#F2C84B');
    } else {
      setPasswordColor('#B42318');
    }
  }, [password]);

  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>{t('auth.signUp')}</Title>
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Label>{t('auth.firstName')}*</Label>
        <CustomInput
          type="text"
          placeholder={t('auth.enterFirstName')}
          value={firstName}
          onChange={(e: any) => setFirstName(e.target.value)}
        />
        <Label>{t('auth.lastName')}*</Label>
        <CustomInput
          type="text"
          placeholder={t('auth.enterLastName')}
          autoComplete="family-name"
          value={lastName}
          onChange={(e: any) => setLastName(e.target.value)}
        />
        <Label>{t('auth.email')}*</Label>
        <CustomInput
          type="text"
          autoComplete="email"
          placeholder={t('auth.enterEmail')}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <Label>{t('auth.password')}*</Label>
        <CustomInput
          type="password"
          autoComplete="new-password"
          placeholder={t('auth.enterPassword')}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          style={{ color: passwordColor }}
        />
        <Label>{t('auth.confirmPassword')}*</Label>
        <CustomInput
          type="password"
          autoComplete="new-password"
          placeholder={t('auth.reenterPassword')}
          value={password2}
          onChange={(e: any) => setPassword2(e.target.value)}
        />
        <Checkbox
          checked={CGAccepted}
          setChecked={setCGAccepted}
          style={{ margin: '0 0 16px 0' }}
        >
          {t('auth.accept')}
          <span onClick={gotoCGV}>{t('auth.termsOfSales')}</span>
          {t('auth.and')}
          <span onClick={gotoPP}>{t('auth.privacyPolicy')}</span>
        </Checkbox>
        <Checkbox
          checked={acceptsMarketing}
          setChecked={setAcceptsMarketing}
          style={{ margin: '0 0 16px 0' }}
        >
          {t('auth.receiveOffers')}
        </Checkbox>
        {!successMessageVisible && (
          <Button
            $type={buttonType.black}
            onClick={(e) => handleRegistration(e)}
            disabled={!canSubmit}
          >
            {t('auth.validateSignUp')}
          </Button>
        )}
        {errorMessage !== '' && (
          <Error>
            {t('auth.errorCreatedAccount')} : {errorMessage}
          </Error>
        )}
        {successMessageVisible && (
          <Success>{t('auth.successCreatedAccount')}</Success>
        )}
        {successMessageVisible ? (
          <Link style={{ margin: '20px 0 0' }} onClick={gotoLoginAfterCreation}>
            {t('auth.signIn')}
          </Link>
        ) : (
          <Link style={{ margin: '20px 0 0' }} onClick={gotoLogIn}>
            {t('auth.alreadyHaveAccount')}
          </Link>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  background-color: rgba(52, 64, 84, 0.7);

  @media (max-width: ${styleConstants.breakpointLg}) {
    padding: 24px 0;
  }
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.form`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
    overflow: auto;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;
`;

const Label = styled.div`
  margin: 0 0 6px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const CustomInput = styled.input`
  width: 100%;

  color: ${colors.gray500};
  background-color: ${colors.white};

  box-sizing: border-box;
  margin: 0 0 16px 0;
  border: solid 1px ${colors.gray300};
  padding: 14px 10px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
`;

const Link = styled.div`
  width: 100%;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 700;
  text-align: center;

  cursor: pointer;
`;

const Error = styled.div`
  color: ${colors.error600};
  text-align: center;
`;

const Success = styled.div`
  color: ${colors.success700};
  text-align: center;
`;

export default RegisterPage;
