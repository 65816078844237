import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../../general/Button';
import colors from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../../constants/styleConstants';
import { createPortal } from 'react-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, modifyElement } from '../../../constants/initialStore';
import { useDispatch } from 'react-redux';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: () => void;
  id?: string;
}

const RemoveElementModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
  id,
}) => {
  const { t } = useTranslation(['common']);

  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const dispatch = useDispatch();

  const handleConfirm = () => {
    console.log(id);
    console.log(
      elements.find((element) => element.id === id)?.type === 'PHOTO',
    );
    console.log(
      elements.find((element) => element.id === id)?.editable === false,
    );
    if (
      id &&
      elements.find((element) => element.id === id)?.type === 'PHOTO' &&
      elements.find((element) => element.id === id)?.editable === false
    ) {
      dispatch(
        modifyElement({
          id,
          modification: {
            content: '',
          },
        }),
      );
      setVisible(false);
    } else {
      handleValidate();
    }
  };

  return createPortal(
    <Container $show={visible} id="removeModal">
      <FormTopContainer>
        <Title>{t('general.deletion')}</Title>
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Text>{t('general.confirmationMessage')}</Text>
        <ButtonContainer>
          <Button $type={buttonType.white} onClick={() => setVisible(false)}>
            {t('general.noOption')}
          </Button>
          <Button
            $type={buttonType.black}
            onClick={() => {
              handleConfirm();
            }}
          >
            {t('general.yesOption')}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: 20px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

export default RemoveElementModal;
